// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_avatar__qbz0B {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--color-white-100);
}
.Avatar_avatar__qbz0B img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,wCAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACR","sourcesContent":[".avatar {\n    width: 40px;\n    height: 40px;\n    border-radius: 10px;\n    border: 1px solid var(--color-white-100);\n\n    img {\n        width: 40px;\n        height: 40px;\n        min-width: 40px;\n        min-height: 40px;\n        border-radius: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `Avatar_avatar__qbz0B`
};
export default ___CSS_LOADER_EXPORT___;
