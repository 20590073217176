import React from "react";

import styles from "./Header.module.scss";
import { RedLogo } from "../../icons/RedLogo";
import { Avatar } from "../Avatar/Avatar";

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Header = (props: HeaderProps) => {
  const { children } = props;
  return (
    <div className={styles.header}>
      {children}
      <RedLogo />
      <Avatar />
    </div>
  );
};
