import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import { Input } from "../Input/Input";

import styles from "./CreateProfileModal.module.scss";
import { UploadPhoto } from "../UploadPhoto/UploadPhoto";
import { api, recsApi } from "../../api/api";
import { Button } from "../Button/Button";
import { CreateProfileIcon } from "../../icons/CreateProfileIcon";
import { ProfileContext } from "../../context/ProfileContext";
import { AxiosError } from "axios";

interface CreateProfileModalProps {
  onClose: () => void;
  open: boolean;
}

export const CreateProfileModal = React.memo(function CreateProfileModal(
  props: CreateProfileModalProps
) {
  const { profile } = useContext(ProfileContext);
  const { onClose, open } = props;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [surname, setSurname] = useState("");
  const [tgLink, setTgLink] = useState("");
  const [photoValue, setPhotoValue] = useState<null | File | string>(null);
  const [payloadPhoto, setPayloadPhoto] = useState(
    profile?.profile_image || ""
  );
  const [company, setCompany] = useState(profile?.hash_tag_company || "");
  const [role, setRole] = useState(profile?.hash_tag_role || "");

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      setLoading(true);

      try {
        await recsApi.completeFullReg({
          first_name: name,
          surname,
          tg_username: tgLink,
          profile_image: payloadPhoto,
          phone_number: phone,
          company_project: company,
          role_position: role,
        });

        onClose();
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          setError(error.response?.data?.detail);
        }
      } finally {
        setLoading(false);
      }
    },
    [name, surname, tgLink, payloadPhoto, phone, company, role, onClose]
  );

  const onPhotoChange = async (value: File | null) => {
    if (!value) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("project_icon", value);

      const response = await api.uploadProjectImage(formData);
      setPayloadPhoto(response.icon_name);
      setPhotoValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setName(profile?.first_name || "");
    setSurname(profile?.surname || "");
    setTgLink(profile?.tg_username || "");
    setCompany(profile?.hash_tag_company || "");
    setRole(profile?.hash_tag_role || "");
    setPayloadPhoto(profile?.profile_image || "");
    setPhotoValue(profile?.profile_image || "");
  }, [profile]);

  return (
    <Modal width={570} open={open} onClose={onClose} id="create-profile">
      <h2 className={styles.formHeader}>Создание профиля</h2>
      <form onSubmit={handleSubmit} className={styles.registerForm}>
        <div className={styles.registerFormHeader}>
          <UploadPhoto
            uploadText="Загрузите <br /> свое фото"
            value={photoValue}
            onChange={onPhotoChange}
            blackIcon
            className={styles.uploadPhoto}
            bodyClassName={styles.uploadPhotoBody}
          />
          <Input
            name="name"
            label="Имя:"
            value={name}
            onChange={setName}
            multiline={false}
          />
          <Input
            name="surname"
            label="Фамилия:"
            value={surname}
            onChange={setSurname}
            multiline={false}
          />
        </div>
        <div className={styles.registerFormBody}>
          <Input
            name="phone"
            label="Телефон:"
            value={phone}
            onChange={setPhone}
            multiline={false}
          />
          <Input
            name="tg"
            label="Ссылка на аккаунт в Telegram:"
            value={tgLink}
            onChange={setTgLink}
            multiline={false}
          />
          <Input
            name="company"
            label="Проект/компания:"
            value={company}
            onChange={setCompany}
            multiline={false}
          />
          <Input
            name="role"
            label="Роль/должность:"
            value={role}
            onChange={setRole}
            multiline={false}
          />
        </div>
        {error && <div className={styles.formError}>{error}</div>}
        <Button
          className={styles.submitButton}
          icon={<CreateProfileIcon />}
          variant="black"
          loading={loading}
        >
          Создать профиль
        </Button>
      </form>
    </Modal>
  );
});
