import React, { useCallback, useEffect, useState } from "react";
import { Section } from "../../components/Section/Section";
import { Header } from "../../components/Header/Header";
import { Button } from "../../components/Button/Button";
import { ReturnIcon } from "../../icons/ReturnIcon";
import { Link, useParams } from "react-router-dom";
import { ProjectToShowData } from "../../types/Projects";
import styles from "./ProjectPage.module.scss";
import { api, recsApi } from "../../api/api";
import classNames from "classnames";
import dayjs from "dayjs";
import { TgIcon } from "../../icons/TgIcon";
import { UserPopupData } from "../../types/User";
require("dayjs/locale/ru");

export const ProjectPage = () => {
  const params = useParams();
  const [project, setProject] = useState<ProjectToShowData | null>(null);
  const [userData, setUserData] = useState<UserPopupData | null>(null);
  const [auditor, setAuditor] = useState<UserPopupData | null>(null);
  const [loading, setLoading] = useState(false);

  const getProject = useCallback(async () => {
    setLoading(true);

    try {
      const data = await api.getProject(Number(params.id));
      if (data.auditor_score && data.auditor_score.user) {
        const auditor = await recsApi.getUserData(data.auditor_score.user.uuid);
        setAuditor(auditor);
      }
      const userData = await recsApi.getUserData(data.user.uuid);
      setProject(data);
      setUserData(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  const getProjectIncomeType = (name: string) => {
    if (name === "buy_part") {
      return "Покупка доли/бизнеса";
    }

    if (name === "by_maturity_date") {
      return "К сроку погашения";
    }

    if (name === "monthly") {
      return "Ежемесячные выплаты";
    }
  };

  useEffect(() => {
    getProject();
  }, [getProject]);

  return (
    <div className={classNames("page", styles.projectPage)}>
      <Header>
        <Link to="/">
          <Button icon={<ReturnIcon />}>К таблице</Button>
        </Link>
      </Header>
      {project && userData && (
        <div className={styles.content}>
          <div className={styles.header}>
            {project.icon_name && (
              <img
                className={styles.headerImage}
                src={project.icon_name}
                alt="img"
              />
            )}
            <Section className={styles.sectionTitle}>
              <h1 className={styles.pageTitle}>{project.title}</h1>
              <div>{project.niche}</div>
              <div>{project.description}</div>
            </Section>
          </div>
          <div className={styles.body}>
            <div className={styles.leftSide}>
              <div className={styles.rowContainer}>
                <div className={styles.columnContainer}>
                  <Section className={styles.money}>
                    <div className={styles.moneyContainer}>
                      <div className={styles.textBold}>Объем раунда:</div>
                      {project.round_volume} RUB
                    </div>
                    <div className={styles.moneyContainer}>
                      <div className={styles.textBold}>Порог входа:</div>
                      {project.entry_threshold} RUB
                    </div>
                    <div className={styles.moneyContainer}>
                      <div className={styles.textBold}>
                        Потенциал роста прибыли за 2025:
                      </div>
                      {project.potential_growth} RUB
                    </div>
                    {project.year_income_rub && (
                      <div className={styles.moneyContainer}>
                        <div className={styles.textBold}>
                          Доходность ежемесячно за 2024:
                        </div>
                        {project.year_income_rub}% в RUB
                      </div>
                    )}
                  </Section>
                  <div className={styles.rowContainer}>
                    <Section className={styles.yearIncome}>
                      <div>Годовая доходность оффера:</div>
                      {project.year_income_rub && (
                        <div>
                          <span>{project.year_income_rub}%</span> в RUB
                        </div>
                      )}
                      {project.year_income_usd && (
                        <div>
                          <span>{project.year_income_usd}%</span> в USD
                        </div>
                      )}
                    </Section>
                    <Section>
                      <div className={styles.textBold}>
                        Инвестиционный горизонт:
                      </div>
                      <div>
                        <span className={styles.textBold}>
                          {project.invest_horizon}
                        </span>{" "}
                        месяцев
                      </div>
                    </Section>
                  </div>
                  <Section className={styles.incomeTypes}>
                    <div className={styles.textBold}>Тип доходности:</div>
                    <div>
                      {project.project_income_types.map((item) => (
                        <span>
                          {getProjectIncomeType(item.income_type.name)}
                        </span>
                      ))}
                    </div>
                  </Section>
                </div>
                <Section className={styles.auditor}>
                  {auditor && project.auditor_score ? (
                    <>
                      <div className={styles.textBold}>Основатель проекта:</div>
                      <div className={styles.userCard}>
                        {auditor.profile_image && (
                          <img src={auditor.profile_image} alt="" />
                        )}

                        <div className={styles.userCardInfo}>
                          <div className={styles.userName}>
                            {auditor.surname} {auditor.first_name}
                          </div>
                          <div className={styles.userRole}>
                            {auditor.hash_tag_company} {auditor.hash_tag_role}
                          </div>
                        </div>
                      </div>

                      <div className={styles.auditorScore}>
                        <div className={styles.textBold}>Оценка аудитора:</div>
                        <div className={styles.verifRating}>
                          {project.auditor_score.auditor_score}
                        </div>
                        <div>
                          <div className={styles.textBold}>
                            {project.auditor_score.auditor_comment_title}
                          </div>
                          <div className={styles.auditorComment}>
                            {project.auditor_score.auditor_comment}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.auditorError}>
                      <div>
                        Проект не прошел аудит от независимых экспертов.
                      </div>
                      Будьте осторожны с принятием решений.rec’s capitals
                      не является партнером этого проекта.
                    </div>
                  )}
                </Section>
              </div>
              <Section className={styles.guarantee}>
                <div className={styles.textBold}>Обеспечение:</div>
                <div>{project.guarantee}</div>
                <div
                  className={classNames(styles.textBold, styles.guaranteeTitle)}
                >
                  Цель привлечения:
                </div>
                <div>{project.purpose}</div>
              </Section>
            </div>
            <div className={styles.rightSide}>
              <Section className={styles.userSection}>
                <div className={styles.textBold}>Основатель проекта:</div>
                <div className={styles.userCard}>
                  {userData.profile_image && (
                    <img src={userData.profile_image} alt="" />
                  )}

                  <div className={styles.userCardInfo}>
                    <div className={styles.userName}>
                      {userData.surname} {userData.first_name}
                    </div>
                    <div className={styles.userRole}>
                      {userData.hash_tag_company} {userData.hash_tag_role}
                    </div>
                  </div>
                </div>
                <div className={styles.cardFooter}>
                  <div className={styles.verifRating}>
                    {userData.average_verif_rate}
                  </div>
                  Рейтинг профиля на базе 358 RECомендаций в REC’s Trakers
                </div>
              </Section>
              <div className={styles.rightSideFooter}>
                <Section className={styles.roundEnd}>
                  <div className={styles.textBold}>
                    Окончание раунда инвестиций:
                  </div>
                  <div>
                    {dayjs(project.round_end_date)
                      .locale("ru")
                      .format("DD MMMM YYYY")}
                    г.
                  </div>
                </Section>
                <Section className={styles.connect}>
                  Просмотреть отчетность и оффер, а также вложить инвестиции
                  можно через менеджера REC’s Capitals в Telegram.
                  <Link to={userData.tg_link}>
                    <Button className={styles.connectButton} icon={<TgIcon />}>
                      Связаться с менеджером
                    </Button>
                  </Link>
                </Section>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
