// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterModal_title__wIo\\+a {
  width: 100%;
  text-align: left;
  font-size: 33px;
  line-height: 33px;
  font-weight: 400;
}

.FilterModal_modalHeader__9fsFJ {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.FilterModal_modalHeader__9fsFJ .FilterModal_headerActions__YWAmE {
  display: flex;
  gap: 30px;
}
.FilterModal_modalHeader__9fsFJ .FilterModal_headerActions__YWAmE svg {
  cursor: pointer;
}

.FilterModal_form__ExQjI {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 30px;
}

.FilterModal_searchButton__v7F-7 {
  border-radius: 6px;
  grid-column-start: 2;
}

.FilterModal_formSubtitle__eMzeI {
  font-weight: 700;
  margin-bottom: 10px;
}

.FilterModal_formInputTitle__wCTPQ {
  font-weight: 600;
  margin-bottom: 5px;
}

.FilterModal_formContainerInputs__aWifu {
  display: flex;
  gap: 10px;
}

.FilterModal_radioBlock__Qw7qt {
  display: flex;
  gap: 5px;
  align-items: center;
}

.FilterModal_formRadioTitle__A3VMw {
  font-weight: 700;
  margin-bottom: 10px;
}

.FilterModal_filterModal__96NGG {
  margin-right: 20px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterModal/FilterModal.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,SAAA;AACR;AACQ;EACI,eAAA;AACZ;;AAIA;EACI,WAAA;EACA,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,cAAA;AADJ;;AAIA;EACI,kBAAA;EACA,oBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,aAAA;EACA,SAAA;AADJ;;AAIA;EACI,aAAA;EACA,QAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,mBAAA;AADJ","sourcesContent":[".title {\n    width: 100%;\n    text-align: left;\n    font-size: 33px;\n    line-height: 33px;\n    font-weight: 400;\n}\n\n.modalHeader{\n    margin-bottom: 20px;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n    \n    .headerActions {\n        display: flex;\n        gap: 30px;\n\n        svg {\n            cursor: pointer;\n        }\n    }\n}\n\n.form {\n    width: 100%;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(3, 1fr);\n    grid-gap: 30px;\n}\n\n.searchButton {\n    border-radius: 6px;\n    grid-column-start: 2;\n}\n\n.formSubtitle {\n    font-weight: 700;\n    margin-bottom: 10px;\n}\n\n.formInputTitle{\n    font-weight: 600;\n    margin-bottom: 5px;\n}\n\n.formContainerInputs {\n    display: flex;\n    gap: 10px;\n}\n\n.radioBlock {\n    display: flex;\n    gap: 5px;\n    align-items: center;\n}\n\n.formRadioTitle {\n    font-weight: 700;\n    margin-bottom: 10px;\n}\n\n.filterModal {\n    margin-right: 20px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `FilterModal_title__wIo+a`,
	"modalHeader": `FilterModal_modalHeader__9fsFJ`,
	"headerActions": `FilterModal_headerActions__YWAmE`,
	"form": `FilterModal_form__ExQjI`,
	"searchButton": `FilterModal_searchButton__v7F-7`,
	"formSubtitle": `FilterModal_formSubtitle__eMzeI`,
	"formInputTitle": `FilterModal_formInputTitle__wCTPQ`,
	"formContainerInputs": `FilterModal_formContainerInputs__aWifu`,
	"radioBlock": `FilterModal_radioBlock__Qw7qt`,
	"formRadioTitle": `FilterModal_formRadioTitle__A3VMw`,
	"filterModal": `FilterModal_filterModal__96NGG`
};
export default ___CSS_LOADER_EXPORT___;
