import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "../Modal/Modal";

import styles from "./AuthModal.module.scss";
import classNames from "classnames";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import { SignInBlackIcon } from "../../icons/SignInBlackIcon";
import { authApi } from "../../api/api";

import Cookies from "js-cookie";
import { AxiosError } from "axios";
import { useFormUtils } from "../../hooks/useFormUtils";
import { CloseIcon } from "../../icons/CloseIcon";
import { ReturnBigBlackIcon } from "../../icons/ReturnBigBlackIcon";
import { ProfileContext } from "../../context/ProfileContext";

interface AuthModalProps {
  onClose: () => void;
  open: boolean;
}

export const AuthModal = React.memo(function AuthModal(props: AuthModalProps) {
  const { setAccessToken, setRefreshToken } = useContext(ProfileContext);
  const { onClose, open } = props;
  const [modalMode, setModalMode] = useState<
    "login" | "register" | "resetPassword"
  >("login");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      setLoading(true);
      try {
        const data = await authApi.getRefreshToken({ login, password });

        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("access_token", data.access_token);
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);

        onClose();
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          console.error(error);
          setError(error.response?.data?.detail);
        }
      } finally {
        setLoading(false);
      }
    },
    [login, password, onClose, setAccessToken, setRefreshToken]
  );

  useEffect(() => {
    setModalMode("login");
  }, []);

  return (
    <Modal
      id="auth"
      open={open}
      onClose={onClose}
      className={styles.modal}
      width={modalMode === "register" ? 570 : 374}
    >
      <div
        className={classNames(styles.modalHeader, {
          [styles.flexEnd]: modalMode === "login",
        })}
      >
        {modalMode !== "login" && (
          <ReturnBigBlackIcon onClick={() => setModalMode("login")} />
        )}
        <CloseIcon className={styles.closeIcon} onClick={onClose} />
      </div>
      {modalMode === "login" ? (
        <>
          <h2 className={classNames("h1", styles.title)}>Вход</h2>
          <form onSubmit={handleLogin} className={styles.form}>
            <Input
              name="login"
              label="Почта или логин:"
              value={login}
              onChange={setLogin}
              multiline={false}
            />
            <Input
              name="password"
              label="Пароль"
              value={password}
              onChange={setPassword}
              multiline={false}
              type="password"
            />
            <Button
              variant="black"
              className={styles.button}
              icon={<SignInBlackIcon />}
              loading={loading}
            >
              Войти
            </Button>
            {error && <div className={styles.formError}>{error}</div>}
          </form>
          <div className={styles.buttons}>
            <button
              className={styles.linkButton}
              onClick={() => setModalMode("register")}
            >
              Создать аккаунт
            </button>
            <button
              className={styles.linkButton}
              onClick={() => setModalMode("resetPassword")}
            >
              Восстановить пароль
            </button>
          </div>
        </>
      ) : modalMode === "resetPassword" ? (
        <ResetPassword setParentMode={setModalMode} />
      ) : (
        <Register setParentMode={setModalMode} />
      )}
    </Modal>
  );
});

const ResetPassword = React.memo(function ResetPassword({
  setParentMode,
}: {
  setParentMode: (mode: "login" | "register" | "resetPassword") => void;
}) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [availableCode, setAvailableCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mode, setMode] = useState<"reset" | "newPassword">("reset");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const { isPasswordValid } = useFormUtils();

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (email && code) {
        setLoading(true);
        try {
          await authApi.sendCode({ login: email, code: Number(code) });
          setMode("newPassword");
        } catch (error) {
          if (error instanceof AxiosError) {
            console.error(error);
            setError(error.response?.data?.detail);
          }
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(true);

        try {
          await authApi.changePassword({ login: email });
          setAvailableCode(true);
        } catch (error) {
          if (error instanceof AxiosError) {
            console.error(error);
            const { status } = error.response!;
            // Пользователь не найден
            if (status === 427) {
              setError("Нет пользователей с такой почтой.");
            } else setError(error.response?.data?.detail);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [code, email]
  );

  const handleChangePassword = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (password !== repeatPassword) {
        setError("Пароли не совпадают");
        return;
      }

      if (!isPasswordValid(password)) {
        setError("Пароль не соответствует условиям");
        return;
      }

      setLoading(true);

      try {
        await authApi.confirmChangePassword({
          login: email,
          code: Number(code),
          new_password: password,
        });

        setParentMode("login");
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [password, code, email, isPasswordValid, repeatPassword, setParentMode]
  );

  return (
    <>
      <h2 className={classNames("h1", styles.title)}>восстановление</h2>
      {mode === "reset" ? (
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input
            name="login"
            label="Почта или логин:"
            value={email}
            disabled={availableCode}
            onChange={setEmail}
            multiline={false}
          />
          <Input
            name="code"
            label="Код из письма:"
            value={code}
            onChange={setCode}
            disabled={!availableCode}
            multiline={false}
          />
          <Button
            variant="black"
            className={styles.button}
            icon={<SignInBlackIcon />}
            loading={loading}
          >
            {availableCode ? "Сбросить пароль" : "Получить код"}
          </Button>
        </form>
      ) : (
        <form onSubmit={handleChangePassword} className={styles.form}>
          <Input
            name="password"
            label="Новый пароль:"
            value={password}
            onChange={setPassword}
            multiline={false}
            hint="Пароль может содержать латинские буквы, цифры и специальные символы (!, @, #, $, %, ^, &, *). Пароль должен быть не менее 8 символов."
            type="password"
          />
          <Input
            name="repeatPassword"
            label="Повторите пароль:"
            value={repeatPassword}
            onChange={setRepeatPassword}
            multiline={false}
            type="password"
          />
          <Button
            variant="black"
            className={styles.button}
            icon={<SignInBlackIcon />}
            loading={loading}
          >
            Войти
          </Button>
        </form>
      )}
      {error && <div className={styles.formError}>{error}</div>}
      <div className={styles.buttons}>
        <button
          className={styles.linkButton}
          onClick={() => setParentMode("register")}
        >
          Создать аккаунт
        </button>
      </div>
    </>
  );
});

const Register = React.memo(function Register({
  setParentMode,
}: {
  setParentMode: (mode: "login" | "register" | "resetPassword") => void;
}) {
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [availableCode, setAvailableCode] = useState(false);
  const [error, setError] = useState("");
  const { isPasswordValid } = useFormUtils();

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (availableCode) {
        setLoading(true);

        try {
          await authApi.confirmSignUp({ email, code });

          setParentMode("login");
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }

        return;
      }

      if (!isPasswordValid(password)) {
        setError("Пароль не соответствует условиям");
        return;
      }

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailPattern.test(email)) {
        setError("Неверный формат почты");
        return;
      }

      setLoading(true);

      try {
        await authApi.signUp({ email, login, password });

        setAvailableCode(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error(error);
          const { status } = error.response!;
          if ([437, 447].includes(status)) {
            await authApi.resendCodeToConfirm({ email_or_login: login });

            setAvailableCode(true);
          }
          setError(error.response?.data?.detail);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      isPasswordValid,
      password,
      email,
      login,
      availableCode,
      code,
      setParentMode,
    ]
  );

  useEffect(() => {
    setError("");
  }, [email, login, password]);

  return (
    <>
      <h2 className={classNames("h1", styles.title)}>Регистрация аккаунта</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Input
          name="email"
          label="Почта:"
          value={email}
          onChange={setEmail}
          multiline={false}
          disabled={availableCode}
        />
        <Input
          name="login"
          label="Логин:"
          value={login}
          onChange={setLogin}
          multiline={false}
          hint="Логин может содержать только латинские буквы, цифры точки, тире и подчеркивания. В логине должны быть минимум 4 буквы."
          disabled={availableCode}
        />
        <Input
          name="password"
          label="Пароль:"
          value={password}
          onChange={setPassword}
          multiline={false}
          hint="Пароль может содержать латинские буквы, цифры и специальные символы (!, @, #, $, %, ^, &, *). Пароль должен быть не менее 8 символов."
          disabled={availableCode}
          type="password"
        />
        <Input
          name="code"
          label="Код из письма:"
          value={code}
          onChange={setCode}
          disabled={!availableCode}
          multiline={false}
        />

        <Button
          variant="black"
          className={styles.button}
          icon={<SignInBlackIcon />}
          loading={loading}
        >
          Зарегистрироваться
        </Button>
      </form>
      {error && <div className={styles.formError}>{error}</div>}

      <div className={styles.formCaption}>
        Нажимая «Зарегистрироваться», вы принимаете{" "}
        <a>согласие на обработку персональных данных</a> и 
        <a>пользовательское соглашение.</a>
      </div>
    </>
  );
});
