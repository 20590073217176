import React from "react";

export const RefreshIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M4 16C4 18.3734 4.70379 20.6935 6.02236 22.6668C7.34094 24.6402 9.21509 26.1783 11.4078 27.0866C13.6005 27.9948 16.0133 28.2324 18.3411 27.7694C20.6689 27.3064 22.8071 26.1635 24.4853 24.4853C26.1635 22.8071 27.3064 20.6689 27.7694 18.3411C28.2324 16.0133 27.9948 13.6005 27.0866 11.4078C26.1783 9.21509 24.6402 7.34094 22.6668 6.02236C20.6935 4.70379 18.3734 4 16 4C12.6453 4.01262 9.42529 5.32163 7.01333 7.65333L4 10.6667"
        stroke="#282828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 4V10.6667H10.6667"
        stroke="#282828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
