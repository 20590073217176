import React from "react";

export const GridIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.862 3.862C4.6059 3.11811 5.61484 2.7002 6.66686 2.7002H25.3335C26.3856 2.7002 27.3945 3.11811 28.1384 3.862C28.8823 4.6059 29.3002 5.61484 29.3002 6.66686V25.3335C29.3002 26.3856 28.8823 27.3945 28.1384 28.1384C27.3945 28.8823 26.3856 29.3002 25.3335 29.3002H6.66686C5.61484 29.3002 4.6059 28.8823 3.862 28.1384C3.11811 27.3945 2.7002 26.3856 2.7002 25.3335V6.66686C2.7002 5.61484 3.11811 4.6059 3.862 3.862ZM6.66686 5.3002C6.3044 5.3002 5.95678 5.44418 5.70048 5.70048C5.44418 5.95678 5.3002 6.3044 5.3002 6.66686V10.7002H10.7002V5.3002H6.66686ZM13.3002 5.3002V10.7002H26.7002V6.66686C26.7002 6.3044 26.5562 5.95678 26.2999 5.70048C26.0436 5.44418 25.696 5.3002 25.3335 5.3002H13.3002ZM26.7002 13.3002H13.3002V26.7002H25.3335C25.696 26.7002 26.0436 26.5562 26.2999 26.2999C26.5562 26.0436 26.7002 25.696 26.7002 25.3335V13.3002ZM10.7002 26.7002V13.3002H5.3002V25.3335C5.3002 25.696 5.44418 26.0436 5.70048 26.2999C5.95678 26.5562 6.3044 26.7002 6.66686 26.7002H10.7002Z"
        fill="#D70034"
      />
    </svg>
  );
};
