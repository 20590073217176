import { DependencyList, useEffect } from "react";

/** Хук useEffect, который может принимать асинхронные функции. Сам он НЕ АСИНХРОНЕН (ваш КЭП ¯\_(ツ)_/¯ )! */
export function useEffectAsync(
  callback: () => void | (() => void | undefined) | Promise<unknown>,
  deps: DependencyList = []
) {
  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
