import React, { useMemo } from "react";

interface UseFormUtilsProps {
  fields?: string[];
}

export const useFormUtils = (props?: UseFormUtilsProps) => {
  const fields = props?.fields ?? [];

  const hasEmptyFields = useMemo(() => {
    if (!props) {
      return;
    }

    return fields.includes("");
  }, [fields]);

  const isPasswordValid = (value: string) => {
    return (
      isPasswordContainCapitalLetter(value) &&
      isPasswordLongEnough(value) &&
      isContainRightSymbols(value)
    );
  };

  const isPasswordContainCapitalLetter = (value: string) => {
    const re = /^.*[A-Z]+.*$/;
    return re.test(value);
  };

  const isPasswordLongEnough = (value: string) => {
    return value.length >= 8;
  };

  const isContainRightSymbols = (value: string) => {
    if (value.length === 0) return false;
    const re = /^[a-zA-Z0-9_-]+/;
    return value.replace(re, "") === "";
  };

  const isUrlValid = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    hasEmptyFields,
    isUrlValid,
    isPasswordValid,
  };
};
