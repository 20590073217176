// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__yEgVW {
  padding: 20px;
  background: var(--color-white-100);
  flex-direction: column;
  align-items: flex-end;
}
.Modal_modal__yEgVW::backdrop {
  background: var(--color-black-60);
}
.Modal_modal__yEgVW .Modal_closeIcon__PA6\\+Y {
  margin-bottom: 20px;
  cursor: pointer;
}

dialog {
  padding: 0;
  border: none;
  border-radius: 10px;
}
dialog::backdrop {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kCAAA;EACA,sBAAA;EACA,qBAAA;AACJ;AACI;EACI,iCAAA;AACR;AAEI;EACI,mBAAA;EACA,eAAA;AAAR;;AAIA;EACI,UAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAGI;EACI,eAAA;AADR","sourcesContent":[".modal {\n    padding: 20px;\n    background: var(--color-white-100);\n    flex-direction: column;\n    align-items: flex-end;\n\n    &::backdrop {\n        background: var(--color-black-60);\n    }\n\n    .closeIcon {\n        margin-bottom: 20px;\n        cursor: pointer;\n    }\n}\n\ndialog {\n    padding: 0;\n    border: none;\n    border-radius: 10px;\n    \n    &::backdrop {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__yEgVW`,
	"closeIcon": `Modal_closeIcon__PA6+Y`
};
export default ___CSS_LOADER_EXPORT___;
