// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__yEgVW {
  padding: 20px;
  background: var(--color-white-100);
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
}
@media (max-width: 779px) {
  .Modal_modal__yEgVW {
    width: 100% !important;
  }
}
.Modal_modal__yEgVW::backdrop {
  background: var(--color-black-60);
}
.Modal_modal__yEgVW .Modal_closeIcon__PA6\\+Y {
  margin-bottom: 20px;
  cursor: pointer;
}

dialog {
  padding: 0;
  border: none;
  border-radius: 10px;
}
@media (max-width: 779px) {
  dialog {
    max-width: 100%;
    width: 100vw;
  }
}
dialog::backdrop {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,kCAAA;EACA,sBAAA;EACA,qBAAA;EACA,sBAAA;AADJ;ACCI;EDLJ;IAQQ,sBAAA;EAAN;AACF;AAEI;EACI,iCAAA;AAAR;AAGI;EACI,mBAAA;EACA,eAAA;AADR;;AAKA;EACI,UAAA;EACA,YAAA;EACA,mBAAA;AAFJ;ACjBI;EDgBJ;IAMQ,eAAA;IACA,YAAA;EADN;AACF;AAGI;EACI,eAAA;AADR","sourcesContent":["@import \"../../styles/mixins\";\n\n.modal {\n    padding: 20px;\n    background: var(--color-white-100);\n    flex-direction: column;\n    align-items: flex-end;\n    box-sizing: border-box;\n\n    @include media-down($tablet) {\n        width: 100% !important;\n    }\n\n    &::backdrop {\n        background: var(--color-black-60);\n    }\n\n    .closeIcon {\n        margin-bottom: 20px;\n        cursor: pointer;\n    }\n}\n\ndialog {\n    padding: 0;\n    border: none;\n    border-radius: 10px;\n\n    @include media-down($tablet) {\n        max-width: 100%;\n        width: 100vw;\n    }\n    \n    &::backdrop {\n        cursor: pointer;\n    }\n}","$desktop: 1200px;\n$tablet: 780px;\n$mobile: 390px;\n\n$desktop-max: $desktop - 1px;\n\n@mixin media($width, $type: max) {\n    @media (#{$type}-width: #{$width}) {\n        @content;\n    }\n  }\n  \n  @mixin media-up($width) {\n    @include media($width + 1px, min) {\n        @content;\n    }\n  }\n  \n  @mixin media-down($width) {\n    @include media($width - 1px , max) {\n        @content;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__yEgVW`,
	"closeIcon": `Modal_closeIcon__PA6+Y`
};
export default ___CSS_LOADER_EXPORT___;
