import React from "react";

export const VkIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M16.6266 22.32C9.33327 22.32 5.17333 17.32 5 9H8.65333C8.77333 15.1067 11.4666 17.6933 13.5999 18.2267V9H17.0401V14.2667C19.1467 14.04 21.3598 11.64 22.1065 9H25.5465C24.9732 12.2533 22.5732 14.6533 20.8665 15.64C22.5732 16.44 25.3067 18.5333 26.3467 22.32H22.5599C21.7465 19.7867 19.7201 17.8267 17.0401 17.56V22.32H16.6266Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
