import React from "react";

export const RedLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136"
      height="40"
      viewBox="0 0 136 40"
      fill="none"
    >
      <path
        d="M58.3669 9.31982H56.5786L54.2029 5.50835H53.3284V9.31982H51.8012V3.99421H53.2501L51.8012 1.70994V0.182742H54.8425C54.8425 0.182742 57.8969 0.182742 57.8969 2.84555C57.8969 4.46412 56.7744 5.10371 55.8868 5.35172L58.3669 9.31982ZM53.6156 1.70994L55.0383 3.98116C55.443 3.942 56.3697 3.7462 56.3697 2.84555C56.3697 1.70994 54.8425 1.70994 54.8425 1.70994H53.6156Z"
        fill="#D70034"
      />
      <path
        d="M65.8763 1.70994H61.3077V3.99421H65.1062V5.50835H61.3077V7.79262H65.8763V9.31982H59.7805V0.182742H65.8763V1.70994Z"
        fill="#D70034"
      />
      <path
        d="M71.7226 7.92315C72.558 7.92315 73.3281 7.58378 73.8763 7.03555L74.9467 8.11895C74.1243 8.94129 72.9887 9.45035 71.7226 9.45035C69.2034 9.45035 67.141 7.62294 67.141 4.75128C67.141 1.9971 69.0467 0.052212 71.7226 0.052212C72.9887 0.052212 74.1243 0.561278 74.9467 1.39667L73.8763 2.46701C73.3281 1.91879 72.558 1.57941 71.7226 1.57941C69.843 1.57941 68.6943 2.91081 68.6943 4.75128C68.6943 6.70923 70.0387 7.92315 71.7226 7.92315Z"
        fill="#D70034"
      />
      <path
        d="M77.6139 0.143583C77.5355 0.448152 77.4355 0.778827 77.3136 1.13561C77.1918 1.49239 77.0569 1.84917 76.909 2.20595C76.7698 2.55403 76.6305 2.88471 76.4913 3.19798H75.6429C75.7038 2.95432 75.7647 2.69326 75.8256 2.4148C75.8952 2.13634 75.9561 1.85787 76.0083 1.57941C76.0693 1.29224 76.1215 1.01378 76.165 0.74402C76.2172 0.474258 76.2607 0.226252 76.2955 0H77.5225L77.6139 0.143583Z"
        fill="#D70034"
      />
      <path
        d="M81.3314 9.45035C79.752 9.41119 78.4989 8.49749 78.4728 6.78755H80C80.0261 7.54462 80.6527 7.92315 81.3314 7.92315C82.0363 7.92315 82.6628 7.4663 82.6628 6.74839C82.6628 5.97826 81.9449 5.50835 81.3314 5.48225C81.2531 5.48225 80.6265 5.48225 79.9608 5.18203C79.008 4.73823 78.4728 3.92894 78.4728 2.84555C78.4728 1.1095 79.8564 0.052212 81.3314 0.052212C82.9108 0.052212 84.1247 1.12256 84.1508 2.67586H82.6367C82.6106 1.91879 82.0232 1.57941 81.3314 1.57941C80.6135 1.57941 80 1.9971 80 2.84555C80 3.60262 80.6527 4.00726 81.3314 4.00726C81.3967 4.00726 82.0363 4.00726 82.6889 4.30748C83.6548 4.75128 84.177 5.66499 84.177 6.74839C84.177 8.49748 82.8064 9.45035 81.3314 9.45035Z"
        fill="#D70034"
      />
      <path
        d="M11.5457 36.1514C13.6509 36.1514 15.5916 35.2962 16.9732 33.9147L19.6705 36.6448C17.5982 38.7171 14.7364 40 11.5457 40C5.19722 40 0 35.3949 0 28.1582C0 21.2177 4.80249 16.3165 11.5457 16.3165C14.7364 16.3165 17.5982 17.5993 19.6705 19.7045L16.9732 22.4018C15.5916 21.0203 13.6509 20.1651 11.5457 20.1651C6.80901 20.1651 3.91436 23.5202 3.91436 28.1582C3.91436 33.0923 7.30242 36.1514 11.5457 36.1514Z"
        fill="#D70034"
      />
      <path
        d="M35.2732 39.6711L34.2535 37.0067H26.1616L25.1748 39.6711H21.0302L30.2075 15.9876L39.4178 39.6711H35.2732ZM30.2075 25.6912L27.4116 33.6844H33.0035L30.2075 25.6912Z"
        fill="#D70034"
      />
      <path
        d="M49.6673 16.6454C49.6673 16.6454 57.3315 16.6454 57.3315 23.3558C57.3315 30.0661 49.6673 30.0661 49.6673 30.0661H45.8516V39.6711H42.003V26.2504H45.6542L42.003 20.494V16.6454H49.6673ZM50.1607 26.2175C51.1804 26.0859 53.5158 25.5925 53.5158 23.3558C53.5158 20.494 49.6673 20.494 49.6673 20.494H46.5753L50.1607 26.2175Z"
        fill="#D70034"
      />
      <path
        d="M68.7788 20.1651H66.2131V36.1514H68.9104V39.6711H59.6672V36.1514H62.3645V20.1651H59.7988V16.6454H68.7788V20.1651Z"
        fill="#D70034"
      />
      <path
        d="M84.226 16.6454V20.1651H79.3906V39.6711H75.575V20.1651H70.7396V16.6454H84.226Z"
        fill="#D70034"
      />
      <path
        d="M99.0691 39.6711L98.0494 37.0067H89.9575L88.9707 39.6711H84.8261L94.0034 15.9876L103.214 39.6711H99.0691ZM94.0034 25.6912L91.2075 33.6844H96.7994L94.0034 25.6912Z"
        fill="#D70034"
      />
      <path
        d="M109.648 35.8225H118.167V39.6711H105.799V16.6454H109.648V35.8225Z"
        fill="#D70034"
      />
      <path
        d="M128.659 40C124.679 39.9013 121.521 37.5988 121.456 33.2897H125.304C125.37 35.1975 126.949 36.1514 128.659 36.1514C130.436 36.1514 132.014 35.0001 132.014 33.191C132.014 31.2503 130.205 30.0661 128.659 30.0003C128.462 30.0003 126.883 30.0003 125.205 29.2437C122.804 28.1254 121.456 26.0859 121.456 23.3558C121.456 18.9809 124.942 16.3165 128.659 16.3165C132.639 16.3165 135.699 19.0138 135.764 22.9281H131.949C131.883 21.0203 130.403 20.1651 128.659 20.1651C126.85 20.1651 125.304 21.2177 125.304 23.3558C125.304 25.2636 126.949 26.2833 128.659 26.2833C128.824 26.2833 130.436 26.2833 132.08 27.0399C134.514 28.1582 135.83 30.4608 135.83 33.191C135.83 37.5988 132.376 40 128.659 40Z"
        fill="#D70034"
      />
    </svg>
  );
};
