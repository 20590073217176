// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_tableHeader__z1a2J {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  grid-template-columns: repeat(10, fit-content);
  grid-template-columns: 93px 106px 70px 41px 41px 65px 106px 81px 177px 197px;
  border-bottom: 1px solid #7E7E7E;
  padding-bottom: 10px;
}
.Table_tableHeader__z1a2J .Table_tableHeadItem__6oHEE {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.Table_tableHeader__z1a2J .Table_tableHeadItem__6oHEE svg {
  fill: rgba(245, 245, 245, 0.6);
  cursor: pointer;
}
.Table_tableHeader__z1a2J .Table_tableHeadItem__6oHEE.Table_active__tJ5tn svg {
  fill: white;
}
.Table_tableHeader__z1a2J .Table_tableHeadItem__6oHEE.Table_activeTrue__ObxPw svg {
  transform: rotate(180deg);
}

.Table_tableBody__vbA3X .Table_row__vg-qa {
  color: white;
  padding: 10px 0;
  display: grid;
  word-break: break-all;
  grid-auto-flow: column;
  grid-gap: 20px;
  grid-template-columns: 93px 106px 70px 41px 41px 65px 106px 81px 177px 197px;
}
.Table_tableBody__vbA3X .Table_row__vg-qa:not(:last-child) {
  border-bottom: 1px solid #3D3D3D;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.module.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,8CAAA;EACA,4EAAA;EACA,gCAAA;EACA,oBAAA;AAHJ;AAKI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AAHR;AAKQ;EACI,8BAAA;EACA,eAAA;AAHZ;AAOY;EACI,WAAA;AALhB;AAUY;EACI,yBAAA;AARhB;;AAeI;EACI,YAAA;EACA,eAAA;EACA,aAAA;EACA,qBAAA;EACA,sBAAA;EACA,cAAA;EACA,4EAAA;AAZR;AAcQ;EACI,gCAAA;AAZZ","sourcesContent":["// .table {\n//     max-height: 800px;\n//     overflow-y: scroll;\n// }\n.tableHeader {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 20px;\n    grid-template-columns: repeat(10, fit-content);\n    grid-template-columns: 93px 106px 70px 41px 41px 65px 106px 81px 177px 197px;\n    border-bottom: 1px solid #7E7E7E;\n    padding-bottom: 10px;\n\n    .tableHeadItem {\n        display: flex;\n        align-items: center;\n        gap: 5px;\n        flex-wrap: wrap;\n\n        svg {\n            fill: #F5F5F599;\n            cursor: pointer;\n        }\n\n        &.active {\n            svg {\n                fill: white;\n            }\n        }\n\n        &.activeTrue {\n            svg {\n                transform: rotate(180deg);\n            }\n        }\n    }\n}\n\n.tableBody {\n    .row {\n        color: white;\n        padding: 10px 0;\n        display: grid;\n        word-break: break-all;\n        grid-auto-flow: column;\n        grid-gap: 20px;\n        grid-template-columns: 93px 106px 70px 41px 41px 65px 106px 81px 177px 197px;\n\n        &:not(:last-child) {\n            border-bottom: 1px solid #3D3D3D;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeader": `Table_tableHeader__z1a2J`,
	"tableHeadItem": `Table_tableHeadItem__6oHEE`,
	"active": `Table_active__tJ5tn`,
	"activeTrue": `Table_activeTrue__ObxPw`,
	"tableBody": `Table_tableBody__vbA3X`,
	"row": `Table_row__vg-qa`
};
export default ___CSS_LOADER_EXPORT___;
