import React, { useMemo } from "react";

import classNames from "classnames";
import styles from "./Button.module.scss";
import { CircularProgress } from "@mui/material";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  icon?: React.ReactNode;
  smallRadius?: boolean;
  variant?: "black";
  disabled?: boolean;
  loading?: boolean;
}

export const Button = React.memo(function Button(props: ButtonProps) {
  const {
    children,
    onClick,
    className,
    icon,
    smallRadius,
    disabled,
    variant,
    loading,
  } = props;

  const buttonClasses = useMemo(() => {
    return [
      styles.button,
      className,
      {
        [styles.smallRadius]: smallRadius,
        [styles.disabled]: disabled,
        [styles.isBlack]: variant === "black",
      },
    ];
  }, [disabled, variant, className, smallRadius]);

  return (
    <button className={classNames(buttonClasses)} onClick={onClick}>
      {loading ? (
        <CircularProgress color="inherit" size="20px" />
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </button>
  );
});
