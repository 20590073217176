import React, { useContext, useState } from "react";
import { WhiteLogo } from "../../icons/WhiteLogo";

import styles from "./MainPage.module.scss";
import classNames from "classnames";
import { Button } from "../../components/Button/Button";
import { SignInIcon } from "../../icons/SignInIcon";
import { VkIcon } from "../../icons/VkIcon";
import { TgIcon } from "../../icons/TgIcon";
import { InstaIcon } from "../../icons/InstaIcon";
import { BigLogo } from "../../icons/BigLogo";
import { PlusIcon } from "../../icons/PlusIcon";
import { GridIcon } from "../../icons/GridIcon";
import { StartIcon } from "../../icons/StarIcon";
import { HandsIcon } from "../../icons/HandsIcon";
import { Modal } from "../../components/Modal/Modal";
import { AuthModal } from "../../components/AuthModal/AuthModal";
import { ProfileContext } from "../../context/ProfileContext";
import { Avatar } from "../../components/Avatar/Avatar";
import { FilterIcon } from "../../icons/FilterIcon";
import { FilterModal } from "../../components/FilterModal/FilterModal";
import { Table } from "../../components/Table/Table";
import { FilteredTableData } from "../../types/Projects";
import { RedLogoLeft } from "../../icons/RedLogoLeft";
import { useNavigate } from "react-router-dom";
import { SignInBlackIcon } from "../../icons/SignInBlackIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { Helmet } from "react-helmet-async";
import { useMedia } from "../../hooks/useMedia";

export const MainPage = () => {
  const [openAuthModal, setOpenAuthModal] = useState(false);

  return (
    <div className={classNames(styles.mainPage, "page")}>
      <Helmet>
        <title>REC's Capitals</title>
        <meta name="title" content="REC's Capitals" />
        <meta name="og:image" content="%PUBLIC_URL%/logo.png" />
        <meta
          name="description"
          content="прозрачная инвестиционная платформа, объединяющая проекты и инвесторов. Мы помогаем основателям сосредоточиться на бизнесе, взяв на себя упаковку инвест-офферов, поиск инвесторов и юридическое сопровождение сделок. Инвесторы получают доступ к тщательно проверенным проектам с полной прозрачностью и надежной поддержкой, что позволяет минимизировать риски и эффективно управлять активами."
        />
      </Helmet>
      <MainPageStart openAuthModal={() => setOpenAuthModal(true)} />
      <MainPageAbout />
      <MainPageTable openAuthModal={() => setOpenAuthModal(true)} />

      <AuthModal open={openAuthModal} onClose={() => setOpenAuthModal(false)} />
    </div>
  );
};

const MainPageStart = ({ openAuthModal }: { openAuthModal: () => void }) => {
  const { profile } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [addProjectReject, setAddProjectReject] = useState(false);

  const { isDesktop } = useMedia();

  const addProjectClick = () => {
    if (profile) {
      navigate("/add-project");
    } else {
      setAddProjectReject(true);
    }
  };

  return (
    <div className={styles.start}>
      <div className={styles.header}>
        <WhiteLogo className={styles.whiteLogo} />
        {profile ? (
          <Avatar />
        ) : (
          <Button icon={<SignInIcon />} onClick={openAuthModal}>
            Войти
          </Button>
        )}
      </div>
      {isDesktop ? (
        <>
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <h1 className="h1">
                инвестиции в <br /> проекты <br /> с recомендацией
              </h1>
              <div>
                <h2 className={styles.subTitle}>168 799 569 ₽</h2>
                <div>Выплачено дивидендов инвесторам</div>
              </div>
              <div className={styles.socials}>
                <VkIcon className={styles.vk} />
                <TgIcon className={styles.tg} />
                <InstaIcon className={styles.inst} />
              </div>
            </div>
            <BigLogo className={styles.bigLogo} />
            <div className={styles.rightSide}>
              <div>
                <h2 className={styles.subTitle}>443 000 000 ₽</h2>
                Привлечено инвестиций в проекты
              </div>
              <div className={styles.info}>
                REC’s Capitals — прозрачная инвестиционная платформа,
                объединяющая проекты и инвесторов. Мы помогаем основателям
                сосредоточиться на бизнесе, взяв на себя упаковку
                инвест-офферов, поиск инвесторов и юридическое сопровождение
                сделок. Инвесторы получают доступ к тщательно проверенным
                проектам с полной прозрачностью и надежной поддержкой, что
                позволяет минимизировать риски и эффективно управлять активами.
                <Button
                  smallRadius
                  className={styles.attachButton}
                  icon={<PlusIcon />}
                  onClick={addProjectClick}
                >
                  Разместить проект
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <MainPageStartDesktopLess addProjectClick={addProjectClick} />
      )}

      <Modal
        className={styles.modal}
        id="add-project-reject"
        open={addProjectReject}
        onClose={() => setAddProjectReject(false)}
        width={570}
      >
        <div className={styles.title}>Размещение проектов ограничено</div>
        <div>
          Войдите или создайте аккаунт на платформе через REC’s Trakers <br />{" "}
          для получения доступа к размещению проектов.
        </div>
        <div className={styles.buttons}>
          <Button
            variant="black"
            icon={<SignInBlackIcon />}
            onClick={() => {
              setAddProjectReject(false);
              openAuthModal();
            }}
          >
            Авторизация
          </Button>
          <Button
            variant="black"
            icon={<CloseIcon />}
            onClick={() => setAddProjectReject(false)}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const MainPageStartDesktopLess = ({
  addProjectClick,
}: {
  addProjectClick: () => void;
}) => {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <h1 className="h1">инвестиции в проекты с recомендацией</h1>
          <div className={styles.moneyInfo}>
            <div>
              <h2 className={styles.subTitle}>443 000 000 ₽</h2>
              Привлечено инвестиций в проекты
            </div>
            <div>
              <h2 className={styles.subTitle}>168 799 569 ₽</h2>
              Выплачено дивидендов инвесторам
            </div>
          </div>
        </div>

        <div className={styles.socials}>
          <VkIcon className={styles.vk} />
          <TgIcon className={styles.tg} />
          <InstaIcon className={styles.inst} />
        </div>

        <BigLogo className={styles.bigLogo} />
      </div>
      <div className={styles.info}>
        REC’s Capitals — прозрачная инвестиционная платформа, объединяющая
        проекты и инвесторов. Мы помогаем основателям сосредоточиться
        на бизнесе, взяв на себя упаковку инвест-офферов, поиск инвесторов
        и юридическое сопровождение сделок. Инвесторы получают доступ
        к тщательно проверенным проектам с полной прозрачностью и надежной
        поддержкой, что позволяет минимизировать риски и эффективно управлять
        активами.
        <Button
          smallRadius
          className={styles.attachButton}
          icon={<PlusIcon />}
          onClick={addProjectClick}
        >
          Разместить проект
        </Button>
      </div>
    </>
  );
};

const MainPageAbout = () => {
  const items = [
    {
      id: 1,
      icon: GridIcon,
      text: "На платформе вы можете ознакомиться с проектами, перейти на страницу каждого из них, а также связаться с менеджером для отчетности проекта и инвестирования. Для некоторых функций нужно зарегистрироваться.",
    },
    {
      id: 2,
      icon: StartIcon,
      text: "Независимые аудиторы оценивают проекты и дают свои комментарии, чтобы вам было проще принять решение. Будьте осторожны с проектами, которые не прошли аудит и отмечены предупреждением.",
    },
    {
      id: 3,
      icon: HandsIcon,
      text: "REC’s Capitals стремится к независимости оценки.  Аудиторы получают вознаграждение за факт аудита, а не за высоту оценки проекта.",
    },
  ];

  return (
    <div className={styles.about}>
      <h2 className="h1">как работает</h2>
      <div className={styles.aboutGrid}>
        {items.map((item) => (
          <div key={item.id}>
            {item.icon()}
            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MainPageTable = React.memo(function MainPageTable({
  openAuthModal,
}: {
  openAuthModal: () => void;
}) {
  const { profile } = useContext(ProfileContext);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [tableFilters, setTableFilters] = useState<null | FilteredTableData>(
    null
  );

  const [limitedViewingModal, setLimitedViewingModal] = useState(false);

  const [sortModalOpen, setSortModalOpen] = useState(false);

  return (
    <div className={classNames(styles.table)}>
      <div className={styles.tableHeader}>
        <RedLogoLeft className={styles.logo} />
        <div className={classNames(styles.auth, styles.isMobile)}>
          {profile ? (
            <Avatar />
          ) : (
            <Button onClick={openAuthModal} icon={<SignInIcon />}>
              Войти
            </Button>
          )}
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() => {
              if (!profile) {
                return setLimitedViewingModal(true);
              }
              setOpenFilterModal(true);
            }}
            icon={<FilterIcon />}
          >
            Фильтры
          </Button>
          <Button
            className={styles.sortButton}
            onClick={() => setSortModalOpen(true)}
          >
            Сортировка
          </Button>
          <div className={classNames(styles.auth, styles.isDesktop)}>
            {profile ? (
              <Avatar />
            ) : (
              <Button onClick={openAuthModal} icon={<SignInIcon />}>
                Войти
              </Button>
            )}
          </div>
        </div>
      </div>

      <Table
        filters={tableFilters}
        onOpenLimitiedVision={() => setLimitedViewingModal(true)}
        sortModalOpen={sortModalOpen}
        sortModalClose={() => setSortModalOpen(false)}
      />

      {!profile && (
        <LimitedViewingModal
          open={limitedViewingModal}
          onClose={() => setLimitedViewingModal(false)}
          openAuthModal={openAuthModal}
        />
      )}

      {profile && (
        <FilterModal
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onSubmit={setTableFilters}
        />
      )}
    </div>
  );
});

const LimitedViewingModal = React.memo(function LimitedViewingModal({
  open,
  openAuthModal,
  onClose,
}: {
  open: boolean;
  openAuthModal: () => void;
  onClose: () => void;
}) {
  return (
    <Modal
      id="limited-viewing"
      open={open}
      onClose={onClose}
      width={570}
      className={styles.modal}
    >
      <div className={styles.title}>Просмотр проектов ограничен</div>
      <div>
        Войдите или создайте аккаунт на платформе через REC’s Trakers для
        получения доступа к страницам проектов.
      </div>

      <div className={styles.buttons}>
        <Button
          variant="black"
          icon={<SignInBlackIcon />}
          onClick={() => {
            onClose();
            openAuthModal();
          }}
        >
          Авторизация
        </Button>
        <Button variant="black" icon={<CloseIcon />} onClick={onClose}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
});
