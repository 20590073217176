import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";

import styles from "./FilterModal.module.scss";
import { RefreshIcon } from "../../icons/RefreshIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { SearchIcon } from "../../icons/SearchIcon";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { api } from "../../api/api";
import { FilteredTableData } from "../../types/Projects";
import { useEffectAsync } from "../../hooks/useEffectAsync";
import { useMedia } from "../../hooks/useMedia";

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: FilteredTableData) => void;
}

export const FilterModal = React.memo(function FilterModal(
  props: FilterModalProps
) {
  const { open, onClose, onSubmit } = props;
  const [annualRubMin, setAnnualRubMin] = useState("");
  const [annualRubMax, setAnnualRubMax] = useState("");
  const [annualUsdMin, setAnnualUsdMin] = useState("");
  const [annualUsdMax, setAnnualUsdMax] = useState("");
  const [auditorScoreMin, setAuditorScoreMin] = useState("");
  const [auditorScoreMax, setAuditorScoreMax] = useState("");
  const [investmentHorizonMin, setInvestmentHorizonMin] = useState("");
  const [investmentHorizonMax, setInvestmentHorizonMax] = useState("");
  const [roundVolumeMin, setRoundVolumeMin] = useState("");
  const [roundVolumeMax, setRoundVolumeMax] = useState("");
  const [entryThresholdMin, setEntryThresholdMin] = useState("");
  const [entryThresholdMax, setEntryThresholdMax] = useState("");
  const [incomeType, setIncomeType] = useState<string[]>([]);
  const [guarantee, setGuarantee] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [countProjects, setCountProjects] = useState(0);
  const { isTablet } = useMedia();

  const onRefresh = useCallback(() => {
    setAnnualRubMin("");
    setAnnualRubMax("");
    setAnnualUsdMin("");
    setAnnualUsdMax("");
    setAuditorScoreMin("");
    setAuditorScoreMax("");
    setInvestmentHorizonMin("");
    setInvestmentHorizonMax("");
    setRoundVolumeMin("");
    setRoundVolumeMax("");
    setEntryThresholdMin("");
    setEntryThresholdMax("");
    setIncomeType([]);
    setGuarantee(false);
  }, []);

  const getProjectsCount = useCallback(async () => {
    setLoading(true);

    try {
      const data = await api.filteredTable({
        annual_rub_min: annualRubMin ? Number(annualRubMin) : undefined,
        annual_rub_max: annualRubMax ? Number(annualRubMax) : undefined,
        annual_usd_min: annualUsdMin ? Number(annualUsdMin) : undefined,
        annual_usd_max: annualUsdMax ? Number(annualUsdMax) : undefined,
        auditor_score_min: auditorScoreMin
          ? Number(auditorScoreMin)
          : undefined,
        auditor_score_max: auditorScoreMax
          ? Number(auditorScoreMax)
          : undefined,
        investment_horizon_min: investmentHorizonMin
          ? Number(investmentHorizonMin)
          : undefined,
        investment_horizon_max: investmentHorizonMax
          ? Number(investmentHorizonMax)
          : undefined,
        round_volume_min: roundVolumeMin ? Number(roundVolumeMin) : undefined,
        round_volume_max: roundVolumeMax ? Number(roundVolumeMax) : undefined,
        entry_threshold_min: entryThresholdMin
          ? Number(entryThresholdMin)
          : undefined,
        entry_threshold_max: entryThresholdMax
          ? Number(entryThresholdMax)
          : undefined,
        income_types: incomeType,
        guarantee,
      });

      setCountProjects(data.amount);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    annualRubMax,
    annualRubMin,
    annualUsdMax,
    annualUsdMin,
    auditorScoreMax,
    auditorScoreMin,
    entryThresholdMax,
    entryThresholdMin,
    guarantee,
    incomeType,
    investmentHorizonMax,
    investmentHorizonMin,
    roundVolumeMax,
    roundVolumeMin,
  ]);

  useEffect(() => {
    if (open) {
      getProjectsCount();
    }
  }, [open, incomeType, guarantee]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        annual_rub_min: annualRubMin ? Number(annualRubMin) : undefined,
        annual_rub_max: annualRubMax ? Number(annualRubMax) : undefined,
        annual_usd_min: annualUsdMin ? Number(annualUsdMin) : undefined,
        annual_usd_max: annualUsdMax ? Number(annualUsdMax) : undefined,
        auditor_score_min: auditorScoreMin
          ? Number(auditorScoreMin)
          : undefined,
        auditor_score_max: auditorScoreMax
          ? Number(auditorScoreMax)
          : undefined,
        investment_horizon_min: investmentHorizonMin
          ? Number(investmentHorizonMin)
          : undefined,
        investment_horizon_max: investmentHorizonMax
          ? Number(investmentHorizonMax)
          : undefined,
        round_volume_min: roundVolumeMin ? Number(roundVolumeMin) : undefined,
        round_volume_max: roundVolumeMax ? Number(roundVolumeMax) : undefined,
        entry_threshold_min: entryThresholdMin
          ? Number(entryThresholdMin)
          : undefined,
        entry_threshold_max: entryThresholdMax
          ? Number(entryThresholdMax)
          : undefined,
        income_types: incomeType,
        guarantee,
      });

      onClose();
    },
    [
      annualRubMax,
      annualRubMin,
      annualUsdMax,
      annualUsdMin,
      auditorScoreMax,
      auditorScoreMin,
      entryThresholdMax,
      entryThresholdMin,
      guarantee,
      incomeType,
      investmentHorizonMax,
      investmentHorizonMin,
      roundVolumeMax,
      roundVolumeMin,
      onClose,
      onSubmit,
    ]
  );

  useEffectAsync(async () => {
    const data = await api.getDefaultFilterValues();
    setAnnualRubMin(String(data.annual_rub_min));
    setAnnualRubMax(String(data.annual_rub_max));
    setAnnualUsdMin(String(data.annual_usd_min));
    setAnnualUsdMax(String(data.annual_usd_max));
    setAuditorScoreMin(String(data.auditor_score_min));
    setAuditorScoreMax(String(data.auditor_score_max));
    setInvestmentHorizonMin(String(data.investment_horizon_min));
    setInvestmentHorizonMax(String(data.investment_horizon_max));
    setRoundVolumeMin(String(data.round_volume_min));
    setRoundVolumeMax(String(data.round_volume_max));
    setEntryThresholdMin(String(data.entry_threshold_min));
    setEntryThresholdMax(String(data.entry_threshold_max));
    setGuarantee(data.guarantee || false);
    setIncomeType(data.income_types || []);
  }, []);

  return (
    <Modal
      dialogClassName={styles.filterModal}
      open={open}
      onClose={onClose}
      width={530}
      id="filter"
    >
      <>
        <div className={styles.modalHeader}>
          <h2 className={styles.title}>Фильтры</h2>
          <div className={styles.headerActions}>
            <RefreshIcon onClick={onRefresh} />
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        <form className={styles.form} onSubmit={onSubmitForm}>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>RUB:</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="annualRubMin"
                label={isTablet ? "min, %" : "Минимум, %:"}
                type="number"
                value={annualRubMin}
                onChange={setAnnualRubMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="annualRubMax"
                label={isTablet ? "max, %" : "Максимум, %:"}
                type="number"
                value={annualRubMax}
                onChange={setAnnualRubMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>USD:</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="annualUsdMin"
                label={isTablet ? "min, %" : "Минимум, %:"}
                type="number"
                value={annualUsdMin}
                onChange={setAnnualUsdMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="annualUsdMax"
                label={isTablet ? "max, %" : "Максимум, %:"}
                type="number"
                value={annualUsdMax}
                onChange={setAnnualUsdMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>Оценка аудитора:</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="auditorScoreMin"
                label={isTablet ? "min" : "Минимум:"}
                type="number"
                value={auditorScoreMin}
                onChange={setAuditorScoreMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="auditorScoreMax"
                label={isTablet ? "max" : "Максимум:"}
                type="number"
                value={auditorScoreMax}
                onChange={setAuditorScoreMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>Инвест. горизонт:</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="investmentHorizonMin"
                label={isTablet ? "min, мес.:" : "Минимум, мес.:"}
                type="number"
                value={investmentHorizonMin}
                onChange={setInvestmentHorizonMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="investmentHorizonMax"
                label={isTablet ? "max, мес.:" : "Максимум, мес.:"}
                type="number"
                value={investmentHorizonMax}
                onChange={setInvestmentHorizonMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>Объем раунда</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="roundVolumeMin"
                label={isTablet ? "min, RUB:" : "Минимум, RUB:"}
                type="number"
                value={roundVolumeMin}
                onChange={setRoundVolumeMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="roundVolumeMax"
                label={isTablet ? "max, RUB:" : "Максимум, RUB:"}
                type="number"
                value={roundVolumeMax}
                onChange={setRoundVolumeMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formInputTitle}>Порог входа:</div>
            <div className={styles.formContainerInputs}>
              <Input
                name="entryThresholdMin"
                label="Минимум, RUB:"
                type="number"
                value={entryThresholdMin}
                onChange={setEntryThresholdMin}
                multiline={false}
                onBlur={getProjectsCount}
              />
              <Input
                name="entryThresholdMax"
                label="Максимум, RUB:"
                type="number"
                value={entryThresholdMax}
                onChange={setEntryThresholdMax}
                multiline={false}
                onBlur={getProjectsCount}
              />
            </div>
          </div>
          <div className={styles.radios}>
            <div className={styles.formRadioTitle}>Тип доходности:</div>
            <div className={styles.radiosContainer}>
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                }}
                control={
                  <Checkbox
                    checked={incomeType.includes("monthly")}
                    sx={{
                      color: "#282828",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#282828",
                      },
                    }}
                    onChange={(e) => {
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "monthly"]
                          : incomeType.filter((type) => type !== "monthly")
                      );
                    }}
                  />
                }
                label="Ежемесячные выплаты"
              />
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                }}
                control={
                  <Checkbox
                    checked={incomeType.includes("by_maturity_date")}
                    sx={{
                      color: "#282828",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#282828",
                      },
                    }}
                    onChange={(e) =>
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "by_maturity_date"]
                          : incomeType.filter(
                              (type) => type !== "by_maturity_date"
                            )
                      )
                    }
                  />
                }
                label="К сроку погашения"
              />
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                }}
                control={
                  <Checkbox
                    checked={incomeType.includes("buy_part")}
                    sx={{
                      color: "#282828",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#282828",
                      },
                    }}
                    onChange={(e) =>
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "buy_part"]
                          : incomeType.filter((type) => type !== "buy_part")
                      )
                    }
                  />
                }
                label="Покупка доли/бизнеса"
              />
            </div>
          </div>
          <div className={styles.radios}>
            <div className={styles.formRadioTitle}>Обеспечение:</div>
            <RadioGroup onChange={(_e) => setGuarantee(!guarantee)}>
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                }}
                control={
                  <Radio
                    checked={guarantee}
                    sx={{
                      width: "fit-content",
                      color: "#282828",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#282828",
                      },
                    }}
                  />
                }
                label="Есть"
              />
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                sx={{
                  "& .MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                }}
                control={
                  <Radio
                    checked={!guarantee}
                    sx={{
                      width: "fit-content",
                      color: "#282828",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#282828",
                      },
                    }}
                  />
                }
                label="Неважно"
              />
            </RadioGroup>
          </div>
          <Button
            className={styles.searchButton}
            icon={<SearchIcon />}
            variant="black"
            loading={loading}
          >
            Показать {countProjects} проектов
          </Button>
        </form>
      </>
    </Modal>
  );
});
