import React from "react";

export const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_223_862)">
        <path
          d="M7.27526 8.72385C7.14785 8.59667 6.996 8.49661 6.82888 8.4297L1.54221 6.3097C1.47909 6.28437 1.42523 6.24034 1.38785 6.18351C1.35047 6.12669 1.33138 6.05979 1.33312 5.9918C1.33486 5.92381 1.35736 5.85798 1.39759 5.80314C1.43783 5.74831 1.49388 5.70709 1.55821 5.68503L14.2249 1.3517C14.2839 1.33036 14.3479 1.32629 14.4092 1.33996C14.4705 1.35363 14.5266 1.38447 14.571 1.42888C14.6154 1.47329 14.6463 1.52943 14.66 1.59073C14.6736 1.65203 14.6695 1.71596 14.6482 1.77503L10.3149 14.4417C10.2928 14.506 10.2516 14.5621 10.1968 14.6023C10.1419 14.6426 10.0761 14.665 10.0081 14.6668C9.94011 14.6685 9.87322 14.6494 9.81639 14.6121C9.75957 14.5747 9.71554 14.5208 9.69021 14.4577L7.57021 9.1697C7.503 9.00269 7.40266 8.85103 7.27526 8.72385ZM7.27526 8.72385L14.5689 1.4317"
          stroke="#F5F5F5"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_223_862">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
