import React, { useContext, useState } from "react";
import { WhiteLogo } from "../../icons/WhiteLogo";

import styles from "./MainPage.module.scss";
import classNames from "classnames";
import { Button } from "../../components/Button/Button";
import { SignInIcon } from "../../icons/SignInIcon";
import { VkIcon } from "../../icons/VkIcon";
import { TgIcon } from "../../icons/TgIcon";
import { InstaIcon } from "../../icons/InstaIcon";
import { BigLogo } from "../../icons/BigLogo";
import { PlusIcon } from "../../icons/PlusIcon";
import { GridIcon } from "../../icons/GridIcon";
import { StartIcon } from "../../icons/StarIcon";
import { HandsIcon } from "../../icons/HandsIcon";
import { Modal } from "../../components/Modal/Modal";
import { AuthModal } from "../../components/AuthModal/AuthModal";
import { ProfileContext } from "../../context/ProfileContext";
import { Avatar } from "../../components/Avatar/Avatar";
import { RedLogo } from "../../icons/RedLogo";
import { FilterIcon } from "../../icons/FilterIcon";
import { FilterModal } from "../../components/FilterModal/FilterModal";
import { Table } from "../../components/Table/Table";
import { FilteredTableData } from "../../types/Projects";
import { Link } from "react-router-dom";

export const MainPage = () => {
  return (
    <div className={classNames(styles.mainPage, "page")}>
      <MainPageStart />
      <MainPageAbout />
      <MainPageTable />
    </div>
  );
};

const MainPageStart = () => {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const { profile } = useContext(ProfileContext);

  return (
    <div className={styles.start}>
      <div className={styles.header}>
        <WhiteLogo />
        {profile ? (
          <Avatar />
        ) : (
          <Button icon={<SignInIcon />} onClick={() => setOpenAuthModal(true)}>
            Войти
          </Button>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <h1 className="h1">
            инвестиции в <br /> проекты <br /> с recомендацией
          </h1>
          <div>
            <h2 className={styles.subTitle}>168 799 569 ₽</h2>
            <div>Выплачено дивидендов инвесторам</div>
          </div>
          <div className={styles.socials}>
            <VkIcon className={styles.vk} />
            <TgIcon className={styles.tg} />
            <InstaIcon className={styles.inst} />
          </div>
        </div>
        <BigLogo className={styles.bigLogo} />
        <div className={styles.rightSide}>
          <div>
            <h2 className={styles.subTitle}>443 000 000 ₽</h2>
            Привлечено инвестиций в проекты
          </div>
          <div className={styles.info}>
            REC’s Capitals — прозрачная инвестиционная платформа, объединяющая
            проекты и инвесторов. Мы помогаем основателям сосредоточиться
            на бизнесе, взяв на себя упаковку инвест-офферов, поиск инвесторов
            и юридическое сопровождение сделок. Инвесторы получают доступ
            к тщательно проверенным проектам с полной прозрачностью и надежной
            поддержкой, что позволяет минимизировать риски и эффективно
            управлять активами.
            <Link to="/add-project">
              <Button
                smallRadius
                className={styles.attachButton}
                icon={<PlusIcon />}
              >
                Разместить проект
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <AuthModal open={openAuthModal} onClose={() => setOpenAuthModal(false)} />
    </div>
  );
};

const MainPageAbout = () => {
  const items = [
    {
      id: 1,
      icon: GridIcon,
      text: "На платформе вы можете ознакомиться <br /> с проектами, перейти на страницу каждого <br /> из них, а также связаться с менеджером для <br /> отчетности проекта и инвестирования. Для <br /> некоторых функций нужно зарегистрироваться.",
    },
    {
      id: 2,
      icon: StartIcon,
      text: "Независимые аудиторы оценивают проекты <br /> и дают свои комментарии, чтобы вам было <br /> проще принять решение. Будьте осторожны <br /> с проектами, которые не прошли аудит <br /> и отмечены предупреждением.",
    },
    {
      id: 3,
      icon: HandsIcon,
      text: "REC’s Capitals стремится к независимости оценки. <br /> Аудиторы получают вознаграждение за факт <br /> аудита, а не за высоту оценки проекта.",
    },
  ];

  return (
    <div className={styles.about}>
      <h2 className="h1">как работает</h2>
      <div className={styles.aboutGrid}>
        {items.map((item) => (
          <div key={item.id}>
            {item.icon()}
            <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MainPageTable = React.memo(function MainPageTable() {
  const { profile } = useContext(ProfileContext);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [tableFilters, setTableFilters] = useState<null | FilteredTableData>(
    null
  );

  return (
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        <RedLogo />
        <div className={styles.buttons}>
          <Button
            onClick={() => setOpenFilterModal(true)}
            icon={<FilterIcon />}
          >
            Фильтры
          </Button>
          {profile ? <Avatar /> : <Button icon={<SignInIcon />}>Войти</Button>}
        </div>
      </div>

      <Table filters={tableFilters} />

      <FilterModal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        onSubmit={setTableFilters}
      />
    </div>
  );
});
