import React, { FormEvent, useContext, useState } from "react";

import styles from "./AddProjectPage.module.scss";
import { Input } from "../../components/Input/Input";
import { Button } from "../../components/Button/Button";
import { SendIcon } from "../../icons/SendIcon";
import classNames from "classnames";
import { Section } from "../../components/Section/Section";
import { Header } from "../../components/Header/Header";
import { ReturnIcon } from "../../icons/ReturnIcon";
import { UploadPhoto } from "../../components/UploadPhoto/UploadPhoto";
import { Checkbox, Radio, RadioGroup } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContext";
import { api } from "../../api/api";
import { Dayjs } from "dayjs";
import { DateInput } from "../../components/Input/DateInput";
import { Modal } from "../../components/Modal/Modal";
import { CloseIcon } from "../../icons/CloseIcon";
import { Link } from "react-router-dom";

const infoItems = [
  {
    title: "Сопровождение",
    sections: [
      {
        title: "Презентация и продвижение вашего проекта среди инвесторов",
        text: "Создаем профессиональную упаковку вашего проекта с размещением на платформе, где ваш оффер увидят инвесторы. Собираем отзывы, чтобы усилить доверие к вашему предложению и оценитьего объективно.",
      },
      {
        title: "Аудит и рейтинг для вашего проекта",
        text: "Проводим аудит финансовой отчётности и предоставляем рейтинг доверия от авторитетного эксперта, на основании которого инвесторы принимают решения. Аудит проводят независимые эксперты, которые дадут честную обратную связь по вашему офферу.",
      },
      {
        title: "Поиск подходящих инвесторов и организация встреч",
        text: "Находим именно тех инвесторов, которые заинтересованы в вашем проекте, и организуем подготовленные встречи, чтобы максимально повысить вероятность успешного сотрудничества.",
      },
      {
        title: "Юридическая поддержка сделки от подготовки до завершения",
        text: "Готовим договор, защищающий ваши интересы, и сопровождаем сделку до успешного закрытия на выгодных для вас условиях.",
      },
    ],
  },
  {
    title: "Гарантии",
    sections: [
      {
        title: "Гарантируем результат или вернем деньги",
        text: "Мы берем на себя ответственность за результат: если в течение 30 дней вы не получите результат, мы вернем вам оплату.",
      },
      {
        title: "Фиксированная стоимость услуг — прозрачность и выгода",
        text: "Стоимость работы составляет предоплата 4% от раунда инвестиций. Для подробностей и оплаты с Вами свяжется менеджер(t.me/ACassist) после публикации проекта.",
      },
      {
        text: "REC’s Capitals обеспечивает вашим проектам успешное привлечение инвестиций и профессиональное сопровождение, пока вы концентрируетесь на бизнесе.",
      },
    ],
  },
];

export const AddProjectPage = () => {
  const { profile: userData } = useContext(ProfileContext);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [niche, setNiche] = useState("");
  const [description, setDescription] = useState("");
  const [roundVolume, setRoundVolume] = useState("");
  const [purpose, setPurpose] = useState("");
  const [potentialGrowth, setPotentialGrowth] = useState("");
  const [entryThreshold, setEntryThreshold] = useState("");
  const [yearIncomeRub, setYearIncomeRub] = useState("");
  const [yearIncomeUsd, setYearIncomeUsd] = useState("");
  const [incomeType, setIncomeType] = useState<string[]>([]);
  const [investHorizon, setInvestHorizon] = useState("");
  const [offer, setOffer] = useState("");
  const [projectType, setProjectType] = useState("");
  const [investType, setInvestType] = useState("");
  const [averageYearIncome, setAverageYearIncome] = useState("");
  const [roundEndDate, setRoundEndDate] = useState<Dayjs | null>(null);
  const [finReport, setFinReport] = useState("");
  const [guarantee, setGuarantee] = useState("");
  const [photoValue, setPhotoValue] = useState<null | File>(null);
  const [payloadPhoto, setPayloadPhoto] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!roundEndDate) {
      return;
    }

    setLoading(true);

    try {
      await api.addProject({
        average_year_income: Number(averageYearIncome),
        description,
        entry_threshold: Number(entryThreshold),
        fin_report: finReport,
        guarantee,
        income_type: incomeType,
        invest_horizon: Number(investHorizon),
        niche,
        offer,
        potential_growth: Number(potentialGrowth),
        purpose,
        round_end_date: roundEndDate.format("YYYY-MM-DD"),
        round_volume: Number(roundVolume),
        title: name,
        year_income_rub: Number(yearIncomeRub),
        year_income_usd: Number(yearIncomeUsd),
        icon_name: payloadPhoto,
        project_type: projectType,
        invest_type: investType,
      });

      setOpenModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onPhotoChange = async (value: File | null) => {
    if (!value) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("project_icon", value);

      const response = await api.uploadProjectImage(formData);
      setPayloadPhoto(response.icon_name);
      setPhotoValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classNames(styles.addProjectPage, "page")}>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        width={570}
        id="add-project"
      >
        <div className={styles.modalText}>
          <div>Проект опубликован на платформе</div>
          <div>В ближайшее время с Вами свяжется менеджер REC’s Capitals.</div>
        </div>
        <Button
          className={styles.closeButton}
          variant="black"
          icon={<CloseIcon />}
          onClick={() => setOpenModal(false)}
        >
          Закрыть
        </Button>
      </Modal>
      <Header>
        <Link to="/">
          <Button icon={<ReturnIcon />}>Отменить</Button>
        </Link>
      </Header>
      <h1 className="h1">добавление проекта на платформу</h1>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.container}>
            <UploadPhoto
              className={styles.uploadPhoto}
              bodyClassName={styles.uploadPhotoBody}
              value={photoValue}
              uploadText="Загрузите логотип проекта <br /> 500x500px"
              onChange={(val) => onPhotoChange(val)}
            />
            {userData && (
              <Section className={styles.userSection}>
                <div className={styles.textBold}>Основатель проекта:</div>
                <div className={styles.userCard}>
                  {userData.profile_image && (
                    <img src={userData.profile_image} alt="" />
                  )}

                  <div className={styles.userCardInfo}>
                    <div className={styles.userName}>
                      {userData.surname} {userData.first_name}
                    </div>
                    <div className={styles.userRole}>
                      {userData.hash_tag_company} {userData.hash_tag_role}
                    </div>
                  </div>
                </div>
                <div className={styles.cardFooter}>
                  <div className={styles.verifRating}>
                    {userData.average_verif_rate}
                  </div>
                  Рейтинг профиля на базе 358 RECомендаций в REC’s Trakers
                </div>
              </Section>
            )}
          </div>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputWrapper}>
              <Input
                name="name"
                value={name}
                onChange={(value) => setName(value)}
                label="Название проекта:"
                hint="До 35 символов"
                required
                maxLength={35}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="niche"
                value={niche}
                onChange={(value) => setNiche(value)}
                label="Ниша проекта:"
                hint="До 80 символов"
                maxLength={80}
                required
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="description"
                value={description}
                onChange={(value) => setDescription(value)}
                label="Описание проекта:"
                hint="До 600 символов. Опишите основную деятельность проекта и процессы, которые важно узнать инвестору"
                maxLength={600}
                required
              />
            </div>
            <div className={styles.container}>
              <div className={styles.inputWrapper}>
                <Input
                  name="roundVolume"
                  value={roundVolume}
                  onChange={(value) => setRoundVolume(value)}
                  label="Объем раунда, ₽:"
                  hint="Сумма инвестиций за этот раунд"
                  required
                />
              </div>
              <div className={styles.inputWrapper}>
                <Input
                  name="entryThreshold"
                  value={entryThreshold}
                  onChange={(value) => setEntryThreshold(value)}
                  label="Порог входа, ₽:"
                  hint="Минимальная сумма для инвестиций"
                  required
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="potentialGrowth"
                value={potentialGrowth}
                onChange={(value) => setPotentialGrowth(value)}
                label="Потенциал роста прибыли за 2025, ₽:"
                hint="Разница между потенциальной прибылью за 2025 год и фактической за 2024 год"
                required
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="averageYearIncome"
                value={averageYearIncome}
                onChange={(value) => setAverageYearIncome(value)}
                label="Средняя ежемесячная доходность за 2024 в RUB, %:"
                hint="Процент ежемесячной доходности"
                required
              />
            </div>
            <div className={styles.container}>
              <div className={styles.inputWrapper}>
                <Input
                  name="yearIncomeRub"
                  value={yearIncomeRub}
                  onChange={(value) => setYearIncomeRub(value)}
                  label="Годовая доходность в RUB, %:"
                  hint="Заполните минимум в одной валюте"
                />
              </div>
              <div>и/или</div>
              <div className={styles.inputWrapper}>
                <Input
                  name="yearIncomeUsd"
                  value={yearIncomeUsd}
                  onChange={(value) => setYearIncomeUsd(value)}
                  label="Годовая доходность в USD, %:"
                  hint="Заполните минимум в одной валюте"
                />
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.inputWrapper}>
                <Input
                  name="investHorizon"
                  value={investHorizon}
                  onChange={(value) => setInvestHorizon(value)}
                  label="Инвест. горизонт, месяцев:"
                  required
                />
              </div>
              <div className={styles.inputWrapper}>
                <DateInput
                  value={roundEndDate}
                  onChange={(value) => setRoundEndDate(value)}
                  label="Завершение раунда инвестиций:"
                  required
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="guarantee"
                value={guarantee}
                onChange={(value) => setGuarantee(value)}
                label="Обеспечение:"
                hint="До 340 символов. Под залог чего привлекаются инвестиции"
                maxLength={340}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                name="purpose"
                value={purpose}
                onChange={(value) => setPurpose(value)}
                label="Цель привлечения:"
                hint="До 340 символов. На что пойдут привлеченные инвестиции"
                maxLength={340}
                required
              />
            </div>
            <div className={styles.container}>
              <div className={styles.inputWrapper}>
                <Input
                  name="offer"
                  value={offer}
                  onChange={(value) => setOffer(value)}
                  label="Подробный документ с оффером:"
                  hint="Прикрепите ссылку на оффер"
                  required
                />
              </div>
              <div className={styles.inputWrapper}>
                <Input
                  name="finReport"
                  value={finReport}
                  onChange={(value) => setFinReport(value)}
                  label="Фин. отчетность проекта:"
                  hint="Прикрепите ссылку на отчетность"
                  required
                />
              </div>
            </div>

            <div className={styles.radioContainer}>
              <div className={styles.radioTitle}>Тип доходности</div>
              <div className={styles.radioRow}>
                <div className={styles.radioBlock}>
                  <Checkbox
                    checked={incomeType.includes("monthly")}
                    required={incomeType.length === 0}
                    sx={{
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                    onChange={(e) =>
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "monthly"]
                          : incomeType.filter((type) => type !== "monthly")
                      )
                    }
                  />
                  <div>Ежемесячные выплаты</div>
                </div>
                <div className={styles.radioBlock}>
                  <Checkbox
                    checked={incomeType.includes("by_maturity_date")}
                    required={incomeType.length === 0}
                    sx={{
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                    onChange={(e) =>
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "by_maturity_date"]
                          : incomeType.filter(
                              (type) => type !== "by_maturity_date"
                            )
                      )
                    }
                  />
                  <div>К сроку погашения</div>
                </div>
                <div className={styles.radioBlock}>
                  <Checkbox
                    checked={incomeType.includes("buy_part")}
                    required={incomeType.length === 0}
                    sx={{
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                    onChange={(e) =>
                      setIncomeType(
                        e.target.checked
                          ? [...incomeType, "buy_part"]
                          : incomeType.filter((type) => type !== "buy_part")
                      )
                    }
                  />
                  <div>Покупка доли/бизнеса</div>
                </div>
              </div>
            </div>
            <div className={styles.radioContainer}>
              <div>Тип проекта</div>
              <RadioGroup
                sx={{
                  flexDirection: "row",
                  gap: "10px",
                }}
                value={projectType}
                onChange={(e) => setProjectType(e.target.value)}
              >
                <div className={styles.radioBlock}>
                  <Radio
                    required={!projectType}
                    checked={projectType === "develop"}
                    value="develop"
                    sx={{
                      width: "fit-content",
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                  />
                  Разработка
                </div>
                <div className={styles.radioBlock}>
                  <Radio
                    required={!projectType}
                    value="with_income"
                    checked={projectType === "with_income"}
                    sx={{
                      width: "fit-content",
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                  />
                  Действующий с прибылью
                </div>
              </RadioGroup>
            </div>
            <div className={styles.radioContainer}>
              <div>Тип инвестиции</div>
              <RadioGroup
                sx={{
                  flexDirection: "row",
                  gap: "10px",
                }}
                value={investType}
                onChange={(e) => setInvestType(e.target.value)}
              >
                <div className={styles.radioBlock}>
                  <Radio
                    checked={investType === "loan_agreement"}
                    value="loan_agreement"
                    required={!investType}
                    sx={{
                      width: "fit-content",
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                  />
                  Договор займа
                </div>
                <div className={styles.radioBlock}>
                  <Radio
                    value="asset_management"
                    checked={investType === "asset_management"}
                    required={!investType}
                    sx={{
                      width: "fit-content",
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                  />
                  Управление активами
                </div>
                <div className={styles.radioBlock}>
                  <Radio
                    checked={investType === "purchase_share_business"}
                    required={!investType}
                    value="purchase_share_business"
                    sx={{
                      width: "fit-content",
                      color: "#F5F5F5",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "#F5F5F5",
                      },
                    }}
                  />
                  Покупка доли/бизнеса
                </div>
              </RadioGroup>
            </div>
            {error && <div className={styles.error}>{error}</div>}
            <Button
              className={styles.submitButton}
              icon={<SendIcon />}
              loading={loading}
            >
              Опубликовать
            </Button>
          </form>
        </div>
        <div className={styles.rightSide}>
          {infoItems.map((infoItem) => (
            <div key={infoItem.title}>
              <h2>{infoItem.title}</h2>
              <div className={styles.infoItems}>
                {infoItem.sections.map((section) => (
                  <Section key={section.title}>
                    <div className={styles.sectionTitle}>{section.title}</div>
                    {section.text}
                  </Section>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
function daysjs(): string {
  throw new Error("Function not implemented.");
}
