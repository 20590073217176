import React, { useCallback, useEffect, useState } from "react";
import { UserPopupData } from "../types/User";
import { recsApi } from "../api/api";
import { Modal } from "../components/Modal/Modal";
import { CreateProfileModal } from "../components/CreateProfileModal/CreateProfileModal";

export const ProfileContextProvider = React.memo(
  function ProfileContextProvider({ children }: { children: React.ReactNode }) {
    const [profile, setProfile] = useState<UserPopupData | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [isFullReg, setIsFullReg] = useState(false);
    const accessTokenStorage = localStorage.getItem("access_token");
    const refreshTokenStorage = localStorage.getItem("refresh_token");

    const getProfile = useCallback(async () => {
      try {
        const data = await recsApi.getUserData();
        setProfile(data);
      } catch (error) {
        console.error(error);
      }
    }, []);

    useEffect(() => {
      if (accessTokenStorage && refreshTokenStorage) {
        setAccessToken(accessTokenStorage);
        setRefreshToken(refreshTokenStorage);
      }
    }, []);

    const checkFullReg = useCallback(async () => {
      try {
        const data = await recsApi.checkFullReg();
        setIsFullReg(data.is_full_reg);
        setModalOpen(!data.is_full_reg);
      } catch (error) {
        console.error(error);
      }
    }, []);

    useEffect(() => {
      if (accessToken && refreshToken) {
        getProfile();
        checkFullReg();
      }
    }, [accessToken, refreshToken, getProfile, checkFullReg]);

    const ctx: ProfileContextType = {
      profile,
      setAccessToken,
      setRefreshToken,
    };

    return (
      <ProfileContext.Provider value={ctx}>
        <>
          {children}
          <CreateProfileModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          />
        </>
      </ProfileContext.Provider>
    );
  }
);

type ProfileContextType = {
  profile: UserPopupData | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<string>>;
};

export const ProfileContext = React.createContext<ProfileContextType>({
  profile: null,
  setAccessToken: () => {},
  setRefreshToken: () => {},
});
