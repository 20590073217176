// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateProfileModal_registerFormHeader__zJdzf {
  display: flex;
  gap: 10px;
  align-items: end;
}
.CreateProfileModal_registerFormHeader__zJdzf .CreateProfileModal_uploadPhoto__mvYRS {
  width: 79px;
  height: 79px;
  margin-right: 10px;
}
.CreateProfileModal_registerFormHeader__zJdzf .CreateProfileModal_uploadPhoto__mvYRS img {
  width: 79px;
  height: 79px;
}
.CreateProfileModal_registerFormHeader__zJdzf .CreateProfileModal_uploadPhoto__mvYRS .CreateProfileModal_uploadPhotoBody__s2Bgp {
  border: 1px solid var(--color-black);
  width: 79px;
  height: 79px;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
}

.CreateProfileModal_registerFormBody__uBOF8 {
  margin-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(4, auto);
}

.CreateProfileModal_registerForm__eYQiP {
  width: 100%;
}

.CreateProfileModal_formHeader__T4DW4 {
  font-size: 33px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
}

.CreateProfileModal_submitButton__6tIOc {
  width: 100%;
}

.CreateProfileModal_formError__W5peT {
  color: #FF0000;
  margin-top: 10px;
  margin-right: auto;
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateProfileModal/CreateProfileModal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;AACZ;AAEQ;EACI,oCAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAZ;;AAKA;EACI,gBAAA;EACA,aAAA;EACA,cAAA;EACA,sCAAA;EACA,mCAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ;;AAKA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ;;AAKA;EACI,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AAFJ","sourcesContent":[".registerFormHeader {\n    display: flex;\n    gap: 10px;\n    align-items: end;\n\n    .uploadPhoto {\n        width: 79px;\n        height: 79px;\n        margin-right: 10px;\n\n        img {\n            width: 79px;\n            height: 79px;\n        }\n\n        .uploadPhotoBody {\n            border: 1px solid var(--color-black);\n            width: 79px;\n            height: 79px;\n            font-weight: 500;\n            font-size: 10px;\n            line-height: 13px;\n        }\n    }\n}\n\n.registerFormBody {\n    margin-top: 10px;\n    display: grid;\n    grid-gap: 10px;\n    grid-template-columns: repeat(2, auto);\n    grid-template-rows: repeat(4, auto);\n}\n\n.registerForm {\n    width: 100%;\n}\n\n.formHeader{\n    font-size: 33px;\n    line-height: 33px;\n    font-weight: 400;\n    margin-bottom: 20px;\n    width: 100%;\n    text-transform: uppercase;\n    text-align: left;\n}\n\n.submitButton {\n    width: 100%;\n}\n\n.formError {\n    color: #FF0000;\n    margin-top: 10px;\n    margin-right: auto;\n    margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registerFormHeader": `CreateProfileModal_registerFormHeader__zJdzf`,
	"uploadPhoto": `CreateProfileModal_uploadPhoto__mvYRS`,
	"uploadPhotoBody": `CreateProfileModal_uploadPhotoBody__s2Bgp`,
	"registerFormBody": `CreateProfileModal_registerFormBody__uBOF8`,
	"registerForm": `CreateProfileModal_registerForm__eYQiP`,
	"formHeader": `CreateProfileModal_formHeader__T4DW4`,
	"submitButton": `CreateProfileModal_submitButton__6tIOc`,
	"formError": `CreateProfileModal_formError__W5peT`
};
export default ___CSS_LOADER_EXPORT___;
