import React from "react";
import { GetProjectsDataResponse } from "../../types/Projects";

import styles from "./ProjectCard.module.scss";
import { useProject } from "../../hooks/useProject";
import classNames from "classnames";
import { VerifRating } from "../VerifRating/VerifRating";
import { WarningIcon } from "../../icons/WarningIcon";
import { useNumbers } from "../../hooks/useNumbers";
import plural from "plural-ru";

interface ProjectCardProps {
  card: GetProjectsDataResponse;
}

export const ProjectCard = React.memo(function ProjectCard({
  card,
}: ProjectCardProps) {
  const { getProjectIncomeTypes } = useProject();
  const { splitNumber } = useNumbers();

  return (
    <div className={styles.projectCard}>
      <div className={styles.header}>
        <img src={card.icon_name} alt="project_image" />
        <div>{card.title}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Ниша:</div>
          {card.niche}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Тип доходности:</div>
          {getProjectIncomeTypes(card.project_income_types)}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Обеспечение:</div>
          {card.guarantee || "Отсутствует"}
        </div>
      </div>
      <div className={classNames(styles.container, styles.row)}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Годовая доходность:</div>
          <div className={styles.currency}>
            {card.year_income_rub ? (
              <span>RUB {card.year_income_rub}%</span>
            ) : null}
            {card.year_income_usd ? (
              <span>USD {card.year_income_usd}%</span>
            ) : null}
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Инвест. горизонт:</div>
          {card.invest_horizon}{" "}
          {plural(card.invest_horizon, "месяц", "месяца", "месяцев")}
        </div>
      </div>
      <div className={classNames(styles.container, styles.row)}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Объем раунда:</div>
          {splitNumber(card.round_volume)} ₽
        </div>
        <div className={styles.divider}></div>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Порог входа:</div>
          {splitNumber(card.entry_threshold)} ₽
        </div>
      </div>
      <div className={classNames(styles.container, styles.row)}>
        <div className={styles.column}>
          <div className={styles.containerTitle}>
            Инвестировано через
            <br /> REC’s Capitals:
          </div>
          {card.recs_invested} ₽
        </div>
        <div className={styles.divider}></div>
        <div className={styles.column}>
          <div className={styles.containerTitle}>Оценка аудитора:</div>
          <div
            className={classNames(styles.auditorScore, {
              [styles.isWarning]: card.auditor_score == null,
            })}
          >
            {card.auditor_score !== null ? (
              <>
                {card.auditor_score}/10
                <VerifRating variant="green" value={9} id="project-card" />
              </>
            ) : (
              <>
                Отсутствует
                <WarningIcon />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
