import React, { useCallback, useEffect, useRef, useState } from "react";

import styles from "./Table.module.scss";
import {
  FilteredTableData,
  GetProjectsDataResponse,
} from "../../types/Projects";
import { api } from "../../api/api";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import classNames from "classnames";
import { useEffectAsync } from "../../hooks/useEffectAsync";
import { Link } from "react-router-dom";

interface TableProps {
  filters: null | FilteredTableData;
}

const columns = [
  {
    accessorKey: "title",
    header: "Проект",
    filter: true,
  },
  {
    accessorKey: "niche",
    header: "Ниша",
    filter: false,
  },
  {
    accessorKey: "auditor_score",
    header: "Оценка аудитора",
    filter: true,
  },
  {
    accessorKey: "year_income_rub",
    header: "RUB",
    filter: true,
  },
  {
    accessorKey: "year_income_usd",
    header: "USD",
    filter: true,
  },
  {
    accessorKey: "invest_horizon",
    header: "Инвест. горизонт",
    filter: true,
  },
  {
    accessorKey: "round_volume",
    header: "Объем раунда",
    filter: true,
  },
  {
    accessorKey: "entry_threshold",
    header: "Порог входа",
    filter: true,
  },
  {
    accessorKey: "income_type",
    header: "Тип доходности",
    filter: false,
  },
  {
    accessorKey: "guarantee",
    header: "Обеспечение",
    filter: false,
  },
];

const fetchSize = 20;

export const Table = React.memo(function Table({ filters }: TableProps) {
  const [items, setItems] = useState<GetProjectsDataResponse[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const canFetchNextPage = useRef(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [activeSortFilter, setActiveSortFilter] = useState({
    accessorKey: "",
    value: false,
  });

  const getData = useCallback(
    async (page: number) => {
      setLoading(true);

      try {
        const data = await api.getProjects({
          ...filters,
          page,
          limit: fetchSize,
          sort_by: activeSortFilter.accessorKey || undefined,
          is_sort_des: activeSortFilter.accessorKey
            ? activeSortFilter.value
              ? "False"
              : "True"
            : undefined,
        });

        if (data.length === fetchSize) {
          canFetchNextPage.current = true;
        } else {
          canFetchNextPage.current = false;
        }
        // setItems([...items, ...data]);
        return data;
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [filters, activeSortFilter]
  );

  useEffectAsync(async () => {
    const response = await getData(1);

    if (response) {
      setCurrentPage(1);
      setItems(response);
    }
  }, [filters]);

  useEffectAsync(async () => {
    if (currentPage === 1) {
      return;
    }

    const response = await getData(currentPage);
    if (response) {
      setItems([...items, ...response]);
    }
  }, [currentPage]);

  const onSortClick = useCallback(
    async (accessorKey: string, value: boolean) => {
      console.log(accessorKey, value);
      setActiveSortFilter({
        accessorKey,
        value,
      });
      if (tableRef.current) {
        tableRef.current.scrollTo(0, 0);
        setCurrentPage(1);
        const data = await api.getProjects({
          ...filters,
          page: 1,
          limit: fetchSize,
          sort_by: accessorKey,
          is_sort_des: value ? "False" : "True",
        });

        if (data.length === fetchSize) {
          canFetchNextPage.current = true;
        } else {
          canFetchNextPage.current = false;
        }

        setItems(data);
      }
    },
    [filters]
  );

  const fetchMoreOnBottomReached = () => {
    if (loading) {
      return;
    }

    if (document) {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (clientHeight === 0) {
        return;
      }

      if (
        scrollTop + clientHeight >= scrollHeight &&
        canFetchNextPage.current
      ) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  };

  const getProjectIncomeType = (name: string) => {
    if (name === "buy_part") {
      return "Покупка доли/бизнеса";
    }

    if (name === "by_maturity_date") {
      return "К сроку погашения";
    }

    if (name === "monthly") {
      return "Ежемесячные выплаты";
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", fetchMoreOnBottomReached);
    return () => document.addEventListener("scroll", fetchMoreOnBottomReached);
  }, []);

  return (
    <div className={styles.table} ref={tableRef}>
      <TableHead onSortClick={onSortClick} activeFilter={activeSortFilter} />

      <div className={styles.tableBody}>
        {items.map((item) => (
          <Link to={`/project/${item.id}`} className={styles.row} key={item.id}>
            <div>{item.title}</div>
            <div>{item.niche}</div>
            <div>{item.auditor_score}</div>
            <div>{item.year_income_rub}%</div>
            <div>{item.year_income_usd}%</div>
            <div>{item.invest_horizon} мес.</div>
            <div>{item.round_volume}</div>
            <div>{item.entry_threshold}</div>
            <div>
              {item.project_income_types
                .map((type) => getProjectIncomeType(type.income_type.name))
                .join("; ")}
            </div>
            <div>{item.guarantee}</div>
          </Link>
        ))}
      </div>
    </div>
  );
});

interface TableHeadProps {
  activeFilter: {
    accessorKey: string;
    value: boolean;
  };
  onSortClick: (accessorKey: string, value: boolean) => void;
}

const TableHead = React.memo(function TableHead(props: TableHeadProps) {
  const { activeFilter, onSortClick } = props;
  const getHeadIcon = (key: string, filter: boolean) => {
    if (!filter) {
      return null;
    }

    return (
      <ArrowDownwardIcon
        onClick={() => onSortClick(key, !activeFilter.value)}
      />
    );
  };

  return (
    <div className={styles.tableHeader}>
      {columns.map((col) => (
        <div
          key={col.accessorKey}
          className={classNames(styles.tableHeadItem, {
            [styles.active]: activeFilter.accessorKey === col.accessorKey,
            [styles.activeTrue]:
              activeFilter.accessorKey === col.accessorKey &&
              activeFilter.value,
          })}
        >
          {col.header}
          {getHeadIcon(col.accessorKey, col.filter)}
        </div>
      ))}
    </div>
  );
});
