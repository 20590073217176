import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProjectPage } from "./pages/ProjectPage/ProjectPage";
import { MainPage } from "./pages/MainPage/MainPage";

import "./App.css";
import { AddProjectPage } from "./pages/AddProjectPage/AddProjectPage";
import { ProfileContextProvider } from "./context/ProfileContext";

function App() {
  return (
    <ProfileContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/project/:id" element={<ProjectPage />} />
          <Route path="/add-project" element={<AddProjectPage />} />
        </Routes>
      </BrowserRouter>
    </ProfileContextProvider>
  );
}

export default App;
