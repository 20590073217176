import React from "react";

export const WhiteLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="232"
      height="66"
      viewBox="0 0 232 66"
      fill="none"
    >
      <path
        d="M10.5729 14.7249H7.74123L3.9794 8.70296H2.59455V14.7249H0.17623V6.31067H2.47053L0.17623 2.70163V0.288724H4.9922C4.9922 0.288724 9.82884 0.288724 9.82884 4.49584C9.82884 7.05311 8.05127 8.06364 6.64575 8.45548L10.5729 14.7249ZM3.04928 2.70163L5.30224 6.29005C5.94299 6.22818 7.41052 5.91884 7.41052 4.49584C7.41052 2.70163 4.9922 2.70163 4.9922 2.70163H3.04928Z"
        fill="#F5F5F5"
      />
      <path
        d="M22.4641 2.70163H15.2298V6.31067H21.2446V8.70296H15.2298V12.312H22.4641V14.7249H12.8115V0.288724H22.4641V2.70163Z"
        fill="#F5F5F5"
      />
      <path
        d="M31.7218 12.5182C33.0446 12.5182 34.2641 11.982 35.1322 11.1159L36.8271 12.8276C35.5249 14.1268 33.7267 14.9311 31.7218 14.9311C27.7326 14.9311 24.4668 12.0439 24.4668 7.50682C24.4668 3.15534 27.4845 0.0824926 31.7218 0.0824926C33.7267 0.0824926 35.5249 0.886795 36.8271 2.20667L35.1322 3.89777C34.2641 3.0316 33.0446 2.4954 31.7218 2.4954C28.7454 2.4954 26.9265 4.59896 26.9265 7.50682C26.9265 10.6003 29.0554 12.5182 31.7218 12.5182Z"
        fill="#F5F5F5"
      />
      <path
        d="M41.0506 0.226855C40.9266 0.708061 40.7681 1.23051 40.5752 1.79421C40.3823 2.35791 40.1687 2.92161 39.9345 3.48531C39.714 4.03526 39.4935 4.55771 39.273 5.05266H37.9295C38.026 4.6677 38.1224 4.25524 38.2189 3.81528C38.3291 3.37532 38.4256 2.93536 38.5083 2.4954C38.6047 2.04169 38.6874 1.60173 38.7563 1.17552C38.839 0.749307 38.9079 0.357468 38.963 0H40.9059L41.0506 0.226855Z"
        fill="#F5F5F5"
      />
      <path
        d="M46.9373 14.9311C44.4363 14.8693 42.4521 13.4257 42.4107 10.724H44.8291C44.8704 11.9202 45.8625 12.5182 46.9373 12.5182C48.0535 12.5182 49.0456 11.7964 49.0456 10.6622C49.0456 9.44539 47.9088 8.70296 46.9373 8.66171C46.8133 8.66171 45.8212 8.66171 44.7671 8.18738C43.2582 7.48619 42.4107 6.20756 42.4107 4.49584C42.4107 1.75297 44.6017 0.0824926 46.9373 0.0824926C49.4383 0.0824926 51.3606 1.77359 51.4019 4.22774H49.0043C48.9629 3.0316 48.0328 2.4954 46.9373 2.4954C45.8005 2.4954 44.8291 3.15534 44.8291 4.49584C44.8291 5.69198 45.8625 6.3313 46.9373 6.3313C47.0407 6.3313 48.0535 6.3313 49.087 6.80563C50.6165 7.50681 51.4433 8.95043 51.4433 10.6622C51.4433 13.4256 49.273 14.9311 46.9373 14.9311Z"
        fill="#F5F5F5"
      />
      <path
        d="M19.7203 59.4413C23.316 59.4413 26.6308 57.9838 28.9905 55.6294L33.5975 60.2822C30.0579 63.8138 25.17 66 19.7203 66C8.87692 66 0 58.152 0 45.8194C0 33.9913 8.20273 25.6388 19.7203 25.6388C25.17 25.6388 30.0579 27.825 33.5975 31.4127L28.9905 36.0094C26.6308 33.655 23.316 32.1975 19.7203 32.1975C11.6299 32.1975 6.68578 37.9153 6.68578 45.8194C6.68578 54.228 12.4726 59.4413 19.7203 59.4413Z"
        fill="#F5F5F5"
      />
      <path
        d="M60.2471 65.4394L58.5054 60.8988H44.6844L42.9989 65.4394H35.9198L51.5949 25.0782L67.3262 65.4394H60.2471ZM51.5949 41.6151L46.8194 55.237H56.3705L51.5949 41.6151Z"
        fill="#F5F5F5"
      />
      <path
        d="M84.8325 26.1993C84.8325 26.1993 97.9231 26.1993 97.9231 37.635C97.9231 49.0707 84.8325 49.0707 84.8325 49.0707H78.3152V65.4394H71.7418V42.5681H77.9781L71.7418 32.758V26.1993H84.8325ZM85.6752 42.512C87.4169 42.2878 91.4059 41.4469 91.4059 37.635C91.4059 32.758 84.8325 32.758 84.8325 32.758H79.5512L85.6752 42.512Z"
        fill="#F5F5F5"
      />
      <path
        d="M117.475 32.1975H113.093V59.4413H117.7V65.4394H101.913V59.4413H106.52V32.1975H102.137V26.1993H117.475V32.1975Z"
        fill="#F5F5F5"
      />
      <path
        d="M143.859 26.1993V32.1975H135.6V65.4394H129.083V32.1975H120.824V26.1993H143.859Z"
        fill="#F5F5F5"
      />
      <path
        d="M169.211 65.4394L167.47 60.8988H153.649L151.963 65.4394H144.884L160.559 25.0782L176.291 65.4394H169.211ZM160.559 41.6151L155.784 55.237H165.335L160.559 41.6151Z"
        fill="#F5F5F5"
      />
      <path
        d="M187.28 58.8807H201.831V65.4394H180.706V26.1993H187.28V58.8807Z"
        fill="#F5F5F5"
      />
      <path
        d="M219.752 66C212.954 65.8318 207.56 61.9078 207.448 54.5643H214.021C214.134 57.8156 216.831 59.4413 219.752 59.4413C222.786 59.4413 225.483 57.4793 225.483 54.3961C225.483 51.0888 222.393 49.0707 219.752 48.9586C219.415 48.9586 216.718 48.9586 213.853 47.6693C209.752 45.7633 207.448 42.2878 207.448 37.635C207.448 30.1794 213.403 25.6388 219.752 25.6388C226.55 25.6388 231.775 30.2355 231.888 36.9063H225.37C225.258 33.655 222.73 32.1975 219.752 32.1975C216.662 32.1975 214.021 33.9913 214.021 37.635C214.021 40.8863 216.831 42.6241 219.752 42.6241C220.033 42.6241 222.786 42.6241 225.595 43.9134C229.753 45.8194 232 49.7434 232 54.3961C232 61.9078 226.101 66 219.752 66Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
