// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  padding: 20px;
  /* height: 100%; */
  max-width: 1160px;
  margin: 0 auto;
}

h1, h2, h3, h4 {
  margin: 0;
}

.h1 {
  font-size: 33px;
  line-height: 43px;
  font-weight: 600;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".page {\n  padding: 20px;\n  /* height: 100%; */\n  max-width: 1160px;\n  margin: 0 auto;\n}\n\nh1, h2, h3, h4 {\n  margin: 0;\n}\n\n.h1 {\n  font-size: 33px;\n  line-height: 43px;\n  font-weight: 600;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
