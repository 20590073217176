// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthModal_modal__pd7uJ .AuthModal_title__J5ruo {
  font-weight: 400;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.AuthModal_modal__pd7uJ .AuthModal_modalHeader__m3wbC {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.AuthModal_modal__pd7uJ .AuthModal_modalHeader__m3wbC svg {
  cursor: pointer;
}
.AuthModal_modal__pd7uJ .AuthModal_modalHeader__m3wbC.AuthModal_flexEnd__WPg2s {
  justify-content: flex-end;
}
.AuthModal_modal__pd7uJ .AuthModal_form__LnK7Q {
  width: 100%;
  display: grid;
  grid-gap: 10px;
}
.AuthModal_modal__pd7uJ .AuthModal_button__r49BK {
  margin-top: 10px;
  width: 100%;
}
.AuthModal_modal__pd7uJ .AuthModal_buttons__hVID0 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
}
.AuthModal_modal__pd7uJ .AuthModal_linkButton__pLJ-m {
  text-decoration: underline;
  color: #7E7E7E;
}
.AuthModal_modal__pd7uJ .AuthModal_formCaption__lWr2W {
  margin-top: 20px;
}
.AuthModal_modal__pd7uJ .AuthModal_formCaption__lWr2W a {
  color: #0036D7;
  text-decoration: underline;
  cursor: pointer;
}
.AuthModal_modal__pd7uJ .AuthModal_formError__ieSH9 {
  color: #FF0000;
  margin-top: 10px;
  margin-right: auto;
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthModal/AuthModal.module.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAGI;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;AADR;AAGQ;EACI,eAAA;AADZ;AAIQ;EACI,yBAAA;AAFZ;AAMI;EACI,WAAA;EACA,aAAA;EACA,cAAA;AAJR;AAOI;EACI,gBAAA;EACA,WAAA;AALR;AAQI;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AANR;AASI;EACI,0BAAA;EACA,cAAA;AAPR;AAUI;EACI,gBAAA;AARR;AAUQ;EACI,cAAA;EACA,0BAAA;EACA,eAAA;AARZ;AAYI;EACI,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;AAVR","sourcesContent":[".modal {\n    .title {\n        font-weight: 400;\n        width: 100%;\n        text-align: left;\n        margin-bottom: 20px;\n    }\n\n    .modalHeader {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        margin-bottom: 20px;\n\n        svg {\n            cursor: pointer;\n        }\n\n        &.flexEnd {\n            justify-content: flex-end;\n        }\n    }\n\n    .form {\n        width: 100%;\n        display: grid;\n        grid-gap: 10px;\n    }\n\n    .button {\n        margin-top: 10px;\n        width: 100%;\n    }\n\n    .buttons {\n        margin-top: 20px;\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n        align-items: end;\n    }\n\n    .linkButton {\n        text-decoration: underline;\n        color: #7E7E7E;\n    }\n\n    .formCaption {\n        margin-top: 20px;\n\n        a {\n            color: #0036D7;\n            text-decoration: underline;\n            cursor: pointer;\n        }\n    }\n\n    .formError {\n        color: #FF0000;\n        margin-top: 10px;\n        margin-right: auto;\n        margin-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `AuthModal_modal__pd7uJ`,
	"title": `AuthModal_title__J5ruo`,
	"modalHeader": `AuthModal_modalHeader__m3wbC`,
	"flexEnd": `AuthModal_flexEnd__WPg2s`,
	"form": `AuthModal_form__LnK7Q`,
	"button": `AuthModal_button__r49BK`,
	"buttons": `AuthModal_buttons__hVID0`,
	"linkButton": `AuthModal_linkButton__pLJ-m`,
	"formCaption": `AuthModal_formCaption__lWr2W`,
	"formError": `AuthModal_formError__ieSH9`
};
export default ___CSS_LOADER_EXPORT___;
