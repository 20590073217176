import React from "react";

export const CreateProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.33301 14.0001C1.33295 12.9737 1.6291 11.969 2.1859 11.1067C2.74271 10.2444 3.53652 9.56112 4.47207 9.13881C5.40762 8.7165 6.44517 8.57314 7.46018 8.72592C8.4752 8.8787 9.42456 9.32114 10.1943 10.0001"
        stroke="#282828"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66634 8.66667C8.50729 8.66667 9.99967 7.17428 9.99967 5.33333C9.99967 3.49238 8.50729 2 6.66634 2C4.82539 2 3.33301 3.49238 3.33301 5.33333C3.33301 7.17428 4.82539 8.66667 6.66634 8.66667Z"
        stroke="#282828"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.667 12.6668L12.0003 14.0002L14.667 11.3335"
        stroke="#282828"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
