import React from "react";

export const ReturnBigBlackIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M11.4999 18.9998L4 11.4999M4 11.4999L11.4999 4M4 11.4999L19.7498 11.5003C20.8331 11.5003 21.9059 11.7136 22.9069 12.1282C23.9078 12.5428 24.8172 13.1505 25.5833 13.9166C26.3494 14.6827 26.9571 15.5921 27.3716 16.593C27.7862 17.594 27.9996 18.6667 27.9996 19.7501C27.9996 20.8335 27.7862 21.9063 27.3716 22.9072C26.9571 23.9081 26.3494 24.8176 25.5833 25.5837C24.8172 26.3497 23.9078 26.9574 22.9069 27.372C21.9059 27.7866 20.8331 28 19.7498 28H14.4998"
        stroke="#282828"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
