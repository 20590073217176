import React, { useRef } from "react";
import { PlusIcon } from "../../icons/PlusIcon";

import styles from "./UploadPhoto.module.scss";
import classNames from "classnames";
import { PlusBlackIcon } from "../../icons/PlusBlackIcon";

interface UploadPhotoProps {
  value: File | null | string;
  onChange: (value: File | null) => void;
  uploadText: string;
  className?: string;
  bodyClassName?: string;
  blackIcon?: boolean;
}

export const UploadPhoto = React.memo(function UploadPhoto(
  props: UploadPhotoProps
) {
  const { value, onChange, uploadText, className, bodyClassName, blackIcon } =
    props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classNames(styles.uploadPhoto, className)}
      onClick={() => inputRef.current?.click()}
    >
      {value ? (
        <img
          src={typeof value === "string" ? value : URL.createObjectURL(value)}
          alt=""
        />
      ) : (
        <div className={classNames(styles.body, bodyClassName)}>
          {blackIcon ? <PlusBlackIcon /> : <PlusIcon />}
          <div dangerouslySetInnerHTML={{ __html: uploadText }}></div>
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        name="myImage"
        // Event handler to capture file selection and update the state
        onChange={(event) => {
          if (!event?.target?.files?.[0]) {
            return;
          }
          onChange(event.target.files[0]); // Update the state with the selected file
        }}
      />
    </div>
  );
});
