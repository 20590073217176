import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

import styles from "./Modal.module.scss";
import classNames from "classnames";

interface ModalProps extends React.HTMLAttributes<HTMLDialogElement> {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  width?: number;
  id: string;
  dialogClassName?: string;
}

export const Modal = React.memo(function Modal(props: ModalProps) {
  const { open, onClose, children, width = 374, id, dialogClassName } = props;
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useLayoutEffect(() => {
    if (open) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [open]);

  const modalStyles = useMemo(() => {
    if (open) {
      return {
        width: `${width}px`,
        display: "flex",
      };
    }
    return {};
  }, [open, width]);

  const dialog: HTMLDialogElement | null = document.querySelector(`#${id}`);
  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (e.target === dialog) {
        onClose();
      }
    },
    [dialog, onClose]
  );

  useEffect(() => {
    dialog?.addEventListener("mousedown", onClickOutside);
    return () => dialog?.removeEventListener("mousedown", onClickOutside);
  }, [dialog, onClickOutside]);

  return (
    <dialog className={dialogClassName} id={id} ref={modalRef}>
      <div
        className={classNames(styles.modal, props.className)}
        style={modalStyles}
      >
        {children}
      </div>
    </dialog>
  );
});
