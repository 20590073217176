import React from "react";

import styles from "./Section.module.scss";
import classNames from "classnames";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Section = (props: SectionProps) => {
  return (
    <div {...props} className={classNames(styles.section, props.className)}>
      {props.children}
    </div>
  );
};
