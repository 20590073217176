import React from "react";

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7929 4.19038C14.0467 4.44422 14.0467 4.85578 13.7929 5.10962L6.45955 12.443C6.20571 12.6968 5.79416 12.6968 5.54032 12.443L2.20698 9.10962C1.95314 8.85578 1.95314 8.44422 2.20698 8.19038C2.46082 7.93654 2.87238 7.93654 3.12622 8.19038L5.99993 11.0641L12.8736 4.19038C13.1275 3.93654 13.539 3.93654 13.7929 4.19038Z"
        fill="#282828"
      />
    </svg>
  );
};
