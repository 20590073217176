import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./Table.module.scss";
import {
  FilteredTableData,
  GetProjectsDataResponse,
} from "../../types/Projects";
import { api } from "../../api/api";
import classNames from "classnames";
import { useEffectAsync } from "../../hooks/useEffectAsync";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../context/ProfileContext";
import {
  FilterLetterIcon,
  FilterLetterReverseIcon,
} from "../../icons/FilterLetterIcon";
import {
  FilterNumberIcon,
  FilterNumberReverseIcon,
} from "../../icons/FilterNumberIcon";

import { useNavigate } from "react-router-dom";
import { WarningIcon } from "../../icons/WarningIcon";
import { StarSpriteIcon } from "../../icons/StarSpriteIcon";
import { useNumbers } from "../../hooks/useNumbers";
import { useMedia } from "../../hooks/useMedia";
import { ProjectCard } from "../ProjectCard/ProjectCard";
import { Modal } from "../Modal/Modal";
import { RefreshIcon } from "../../icons/RefreshIcon";
import { CloseIcon } from "../../icons/CloseIcon";
import { Button } from "../Button/Button";
import { CheckIcon } from "../../icons/CheckIcon";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface TableProps {
  filters: null | FilteredTableData;
  onOpenLimitiedVision: () => void;
  sortModalOpen: boolean;
  sortModalClose: () => void;
}

const columns = [
  {
    accessorKey: "title",
    header: "Проект",
    filter: true,
    filterIcon: FilterLetterIcon,
    filterReverseIcon: FilterLetterReverseIcon,
    className: styles.isTitle,
  },
  {
    accessorKey: "niche",
    header: "Ниша",
    filter: false,
    className: styles.isNiche,
  },
  {
    accessorKey: "auditor_score",
    header: "Оценка аудитора",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isAuditorScore,
  },
  {
    accessorKey: "year_income_rub",
    header: "RUB",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isYearIncomeRub,
  },
  {
    accessorKey: "year_income_usd",
    header: "USD",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isYearIncomeUsd,
  },
  {
    accessorKey: "invest_horizon",
    header: "Инвест. горизонт",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isInvestHorizon,
  },
  {
    accessorKey: "round_volume",
    header: "Объем раунда",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isRoundVolume,
  },
  {
    accessorKey: "entry_threshold",
    header: "Порог входа",
    filter: true,
    filterIcon: FilterNumberIcon,
    filterReverseIcon: FilterNumberReverseIcon,
    className: styles.isEntryThreshold,
  },
  {
    accessorKey: "income_type",
    header: "Тип доходности",
    filter: false,
    className: styles.isIncomeType,
  },
  {
    accessorKey: "guarantee",
    header: "Обеспечение",
    filter: false,
    className: styles.isGuarantee,
  },
];

const fetchSize = 20;

export const Table = React.memo(function Table({
  filters,
  onOpenLimitiedVision,
  sortModalOpen,
  sortModalClose,
}: TableProps) {
  const [items, setItems] = useState<GetProjectsDataResponse[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);
  const canFetchNextPage = useRef(false);
  const [loading, setLoading] = useState(false);
  const { profile } = useContext(ProfileContext);
  const { splitNumber } = useNumbers();

  const { isDesktop } = useMedia();

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [activeSortFilter, setActiveSortFilter] = useState<{
    accessorKey: string | undefined;
    value: boolean | undefined;
  }>({
    accessorKey: "",
    value: false,
  });

  const getData = useCallback(
    async (page: number) => {
      setLoading(true);

      try {
        const data = await api.getProjects({
          ...filters,
          page,
          limit: fetchSize,
          sort_by: activeSortFilter.accessorKey || undefined,
          is_sort_des: activeSortFilter.accessorKey
            ? activeSortFilter.value
              ? "False"
              : "True"
            : undefined,
        });

        if (data.length === fetchSize) {
          canFetchNextPage.current = true;
        } else {
          canFetchNextPage.current = false;
        }

        return data;
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [filters, activeSortFilter]
  );

  useEffectAsync(async () => {
    const response = await getData(1);

    if (response) {
      setCurrentPage(1);
      setItems(response);
    }
  }, [filters]);

  useEffectAsync(async () => {
    if (currentPage === 1) {
      return;
    }

    const response = await getData(currentPage);
    if (response) {
      setItems([...items, ...response]);
    }
  }, [currentPage]);

  const onSortClick = useCallback(
    async (accessorKey: string | undefined, value: boolean | undefined) => {
      setActiveSortFilter({
        accessorKey,
        value,
      });
      if (tableRef.current) {
        tableRef.current.scrollTo(0, 0);
        setCurrentPage(1);
        const data = await api.getProjects({
          ...filters,
          page: 1,
          limit: fetchSize,
          sort_by: accessorKey,
          is_sort_des:
            value === undefined ? undefined : value ? "True" : "False",
        });

        if (data.length === fetchSize) {
          canFetchNextPage.current = true;
        } else {
          canFetchNextPage.current = false;
        }

        setItems(data);
      }
    },
    [filters]
  );

  const fetchMoreOnBottomReached = () => {
    if (loading) {
      return;
    }

    if (document) {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (clientHeight === 0) {
        return;
      }

      if (
        scrollTop + clientHeight >= scrollHeight &&
        canFetchNextPage.current
      ) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  };

  const getProjectIncomeType = (name: string) => {
    if (name === "buy_part") {
      return "Покупка доли/бизнеса";
    }

    if (name === "by_maturity_date") {
      return "К сроку погашения";
    }

    if (name === "monthly") {
      return "Ежемесячные выплаты";
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", fetchMoreOnBottomReached);
    return () => document.addEventListener("scroll", fetchMoreOnBottomReached);
  }, []);

  const onRowClick = (id: number) => {
    if (!profile) {
      return onOpenLimitiedVision();
    }

    navigate(`/project/${id}`);
  };

  return (
    <div className={styles.table} ref={tableRef}>
      <TableHead onSortClick={onSortClick} activeFilter={activeSortFilter} />

      <div className={styles.tableBody}>
        {items.map((item) =>
          isDesktop ? (
            <div
              onClick={() => onRowClick(item.id)}
              className={styles.row}
              key={item.id}
            >
              <div className={styles.projectTitle}>
                {item.icon_name && (
                  <img src={item.icon_name} alt="project_icon" />
                )}
                <div className={styles.rowText}>{item.title}</div>
              </div>
              <div className={styles.rowItem}>
                {item.title.length > 20 || item.niche.length > 22 ? (
                  <div className={classNames(styles.tooltip, styles.title)}>
                    <div>{item.title}</div>
                    <div>{item.niche}</div>
                  </div>
                ) : null}
                <div className={styles.rowText}>{item.niche}</div>
              </div>
              <div
                className={classNames(styles.auditorScore, styles.rowItem, {
                  [styles.isGreen]: item.auditor_score > 7,
                  [styles.isRed]: item.auditor_score < 6,
                })}
              >
                {item.auditor_score ? (
                  `${item.auditor_score}/10`
                ) : (
                  <>
                    <div
                      className={classNames(styles.tooltip, styles.upperCenter)}
                    >
                      Сторонний проект без аудита
                    </div>
                    <WarningIcon />
                  </>
                )}
              </div>
              <div>
                {item.year_income_rub ? `${item.year_income_rub}%` : null}
              </div>
              <div>
                {item.year_income_usd ? `${item.year_income_usd}%` : null}
              </div>
              <div>
                {item.invest_horizon ? `${item.invest_horizon} мес.` : null}
              </div>
              <div className={styles.rowItem}>
                <div
                  className={classNames(styles.tooltip, styles.bottomCenter)}
                >
                  Остаток: $
                  {splitNumber(item.round_volume - item.recs_invested)}
                </div>
                {item.round_volume
                  ? `${splitNumber(item.round_volume)} ₽`
                  : null}
              </div>
              <div>
                {item.entry_threshold
                  ? `${splitNumber(item.entry_threshold)} ₽`
                  : null}
              </div>
              <div className={styles.rowItem}>
                {item.project_income_types
                  .map((type) => getProjectIncomeType(type.income_type.name))
                  .join("; ").length > 38 ? (
                  <div
                    className={classNames(
                      styles.tooltip,
                      styles.incomeTypes,
                      styles.upperCenter
                    )}
                  >
                    {item.project_income_types
                      .map((type) =>
                        getProjectIncomeType(type.income_type.name)
                      )
                      .join("; ")}
                  </div>
                ) : null}
                <div className={styles.rowText}>
                  {item.project_income_types
                    .map((type) => getProjectIncomeType(type.income_type.name))
                    .join("; ")}
                </div>
              </div>
              <div className={styles.rowItem}>
                {item.guarantee && item.guarantee?.length > 42 ? (
                  <div
                    className={classNames(
                      styles.tooltip,
                      styles.bottomRight,
                      styles.guarantee
                    )}
                  >
                    {item.guarantee}
                  </div>
                ) : null}
                <div className={styles.rowText}>{item.guarantee}</div>
              </div>
            </div>
          ) : (
            <div onClick={() => onRowClick(item.id)} key={item.id}>
              <ProjectCard card={item} />
            </div>
          )
        )}
      </div>

      <SortModal
        open={sortModalOpen}
        onClose={sortModalClose}
        onSort={onSortClick}
      />
    </div>
  );
});

interface TableHeadProps {
  activeFilter: {
    accessorKey?: string;
    value?: boolean;
  };
  onSortClick: (accessorKey?: string, value?: boolean) => void;
}

const TableHead = React.memo(function TableHead(props: TableHeadProps) {
  const { activeFilter, onSortClick } = props;

  const getHeadIcon = (
    key: string,
    filter: boolean,
    FilterIcon?: (props: React.HTMLAttributes<HTMLOrSVGElement>) => JSX.Element,
    FilterReverseIcon?: (
      props: React.HTMLAttributes<HTMLOrSVGElement>
    ) => JSX.Element
  ) => {
    if (!filter) {
      return null;
    }

    if (!FilterIcon || !FilterReverseIcon) {
      return null;
    }

    if (activeFilter.accessorKey === key) {
      if (activeFilter.value) {
        return <FilterIcon onClick={() => onSortClick(key, false)} />;
      }

      return <FilterReverseIcon onClick={() => onSortClick(key, true)} />;
    }

    return <FilterIcon onClick={() => onSortClick(key, true)} />;
  };

  return (
    <div className={styles.tableHeader}>
      {columns.map((col) => (
        <div
          key={col.accessorKey}
          className={classNames(styles.tableHeadItem, col.className, {
            [styles.active]: activeFilter.accessorKey === col.accessorKey,
            [styles.activeTrue]:
              activeFilter.accessorKey === col.accessorKey &&
              activeFilter.value,
          })}
        >
          {col.accessorKey === "auditor_score" && (
            <div className={styles.stars}>
              <StarSpriteIcon className={styles.isGreen} />
              <StarSpriteIcon className={styles.isWhite} />
              <StarSpriteIcon className={styles.isRed} />
            </div>
          )}
          {col.accessorKey === "year_income_rub" && (
            <div className={styles.incomeCaption}>
              Годовая <br />
              доходность
            </div>
          )}
          {col.header}
          {getHeadIcon(
            col.accessorKey,
            col.filter,
            col.filterIcon,
            col.filterReverseIcon
          )}
        </div>
      ))}
    </div>
  );
});

const SortModal = React.memo(function SortModal({
  open,
  onClose,
  onSort,
}: {
  open: boolean;
  onClose: () => void;
  onSort: (accessorKey?: string, value?: boolean) => Promise<void>;
}) {
  const [parameter, setParameter] = useState("");
  const [sortType, setSortType] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);

    try {
      await onSort(parameter, sortType === "True");

      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [onSort, parameter, sortType, onClose]);

  const onRefresh = useCallback(async () => {
    setLoading(true);

    try {
      await onSort(undefined, undefined).then(() => {
        setParameter("");
        setSortType("");
        onClose();
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [onSort, onClose]);

  return (
    <Modal
      className={styles.sortModal}
      open={open}
      onClose={onClose}
      id="sort-modal"
    >
      <div className={styles.header}>
        Сортировка
        <div className={styles.headerActions}>
          <RefreshIcon onClick={onRefresh} />
          <CloseIcon onClick={onClose} />
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.formSection}>
          <div className={styles.subtitle}>Параметр:</div>
          <RadioGroup
            sx={{
              gap: "10px",
            }}
            value={parameter}
            onChange={(e) => setParameter(e.target.value)}
          >
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  checked={parameter === "auditor_score"}
                  value="auditor_score"
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Оценка аудитора"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  value="year_income_rub"
                  checked={parameter === "year_income_rub"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Годовая доходность в RUB"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  value="year_income_usd"
                  checked={parameter === "year_income_usd"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Годовая доходность в USD"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  value="invest_horizon"
                  checked={parameter === "invest_horizon"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Инвестиционный горизонт"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  value="round_volume"
                  checked={parameter === "round_volume"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Объем раунда"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!parameter}
                  value="entry_threshold"
                  checked={parameter === "entry_threshold"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Порог входа"
            />
          </RadioGroup>
        </div>
        <div className={styles.formSection}>
          <div className={styles.subtitle}>Тип сортировки:</div>
          <RadioGroup
            sx={{
              gap: "10px",
            }}
            value={sortType}
            onChange={(e) => setSortType(e.target.value)}
          >
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!sortType}
                  value="True"
                  checked={sortType === "True"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Убывание"
            />
            <FormControlLabel
              style={{
                margin: 0,
                gap: "8px",
              }}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  display: "none",
                },
              }}
              control={
                <Radio
                  required={!sortType}
                  value="False"
                  checked={sortType === "False"}
                  sx={{
                    width: "fit-content",
                    color: "#282828",
                    padding: 0,
                    "&.Mui-checked": {
                      color: "#282828",
                    },
                  }}
                />
              }
              label="Возрастание"
            />
          </RadioGroup>
        </div>
      </div>
      <Button
        className={styles.submitButton}
        variant="black"
        icon={<CheckIcon />}
        onClick={onSubmit}
        loading={loading}
      >
        Применить
      </Button>
    </Modal>
  );
});
