// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__JBBzO {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--color-white-100);
  border: 1px solid var(--color-white-100);
  justify-content: center;
}
.Button_button__JBBzO.Button_smallRadius__vWB9I {
  border-radius: 6px;
}
.Button_button__JBBzO.Button_isBlack__iBJFg {
  color: var(--color-black);
  border: 1px solid var(--color-black);
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,6BAAA;EACA,wCAAA;EACA,uBAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,yBAAA;EACA,oCAAA;AAAR","sourcesContent":[".button {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 16px;\n    line-height: 20px;\n    padding: 10px 20px;\n    border-radius: 10px;\n    color: var(--color-white-100);\n    border: 1px solid var(--color-white-100);\n    justify-content: center;\n\n    &.smallRadius {\n        border-radius: 6px;\n    }\n\n    &.isBlack {\n        color: var(--color-black);\n        border: 1px solid var(--color-black);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__JBBzO`,
	"smallRadius": `Button_smallRadius__vWB9I`,
	"isBlack": `Button_isBlack__iBJFg`
};
export default ___CSS_LOADER_EXPORT___;
